/**
 * Voice reader class to help assistive technology to read content from elements.<br>
 * Good use case is to focus off-screen elements which can cause the tv flick to element position
 * 
 * note: focus({preventScroll: true)} not working on TV.
 * 
 * @class
 */
class VoiceReader {
  constructor() {
    this.element = Object.assign(document.createElement("div"), { id: "voiceReader", tabIndex: -1 })
    
    document.body.appendChild(this.element);
  }

  read(text, addtionalText) {
    this.element.innerText = "";

    if (text)
      this.element.innerText = text;

    if (addtionalText)
      this.element.innerText += addtionalText;

    this.element.blur();
    this.element.focus();
  }
}

export default new VoiceReader();