import React, { useState, useEffect, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setKeyHandler } from "Services/redux/app/actions";
import CarouselManager from '../../components/carousel/CarouselManager';
import { KeyHandlerCode } from 'data/constants';
import { CarouselManagerTypes } from 'data/constants';
import WithPage from 'HOC/withPage';
import WithKeyHandler from 'HOC/WithKeyHandler';
import useKeyHandler from 'hooks/useKeyHandler';
import useLanguage from 'hooks/useLanguage';
import './liveStyle.css'

function LiveNew(props) {
	const { videos, carousels } = useSelector(({ entities }) => ({
		videos: entities.videos,
		carousels: entities.carousels
	}), shallowEqual);
	const dispatch = useDispatch();
	const getWord = useLanguage();

	// const findFirstActiveVideo = mediaItem => {
	// 	const { categories, playlists, videos } = mediaItem;
	// 	let playlistReference;

	// 	if (categories) playlistReference = categories[0].playlists[0];
	// 	else if (playlists) playlistReference = playlists[0];
	// 	else if (videos) playlistReference = mediaItem
	// 	else playlistReference = mediaItem[0];

	// 	return { video: playlistReference.videos[0], playlist: playlistReference };
	// }

	// const initValues = useMemo(() => findFirstActiveVideo(props.container), [props.container])

	const [activeItem, setActiveItem] = useState(videos[carousels[props.container.content[0]].videos[0]]);
	const isImage1Active = useRef(false);
	const img0 = useRef();
	const img1 = useRef();

	useEffect(() => {
		if (!props.container.content.length) return
		img1.current.src = activeItem.thumbnail // this will cause isImg1Active change to 'true' 
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isImage1Active.current) {
			img0.current.src = activeItem.thumbnail;
		} else {
			img1.current.src = activeItem.thumbnail;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeItem])

	const onItemSelected = activeItem => {
		setActiveItem(activeItem)
	}

	const onLoad = () => {
		if (isImage1Active.current) {
			img1.current.style.opacity = "0"
		} else {
			img1.current.style.opacity = "1"
		}
		isImage1Active.current = !isImage1Active.current
	}

	const openMenu = () => dispatch(setKeyHandler(KeyHandlerCode.MENU));

	// this will activate when there isn't videos
	useKeyHandler({
		keys: { back: openMenu, left: openMenu, right: openMenu },
		isActive: props.isActive && !props.container
	})

	return (
		<>
			{props.container.content.length
				? <>
					<div className="active_video_container list">
						<div className="activeVideo_thumbnail">
							<div className="live_ratioImage">

							</div>
							<img onLoad={onLoad} ref={img0} alt="live" />
							<img onLoad={onLoad} ref={img1} alt="live" />
						</div>

						<div className="ativeVideo_data">
							<h2 className="live_video_title cutLines">{activeItem.title}</h2>
							<p className="live_video_description cutLines">{activeItem.description}</p>
						</div>
					</div>

					<CarouselManager
						carouselHeader="h3"
						content={props.container.content}
						carouselManagerType={CarouselManagerTypes.LIVE}
						type="live"
						onItemSelected={onItemSelected}
					/>
				</>
				: <div className="no_live">
					{getWord("noLiveVideos")}
				</div>
			}
		</>
	)
}

export default React.memo(
	WithPage(WithKeyHandler(LiveNew), KeyHandlerCode.live)
)
