import React from 'react';
import useLanguage from 'hooks/useLanguage';
import ImageWloader from '../../../Widgets/images/ImageWloader';
import SkeletonLoader from 'components/Widgets/loaders/SkeletonLoader';

import './ads.css';

function AdsMessage({ img }) {

	const getWord = useLanguage();

	return (
		<div className="ads-message-container list">
			<div className="ads-message-imageContainer">
				<div>
					<ImageWloader
						src={img}
						layout="cover"
						alt="video thumbnail"
						className="ads-message-img">
						<SkeletonLoader />
					</ImageWloader>
				</div>
			</div>

			<p id="message-ads">{getWord("adsMessage")}</p>
		</div>
	)
}

export default React.memo(AdsMessage);
