import React, { useState, useCallback, useMemo } from 'react'
import Grid from 'components/videosGrid/Grid';
import Thumbnail from 'components/carousel/types/Thumbnail';
import Input from './Input';
import Keyboard from 'components/keyboard/Keyboard';

import WithPage from 'HOC/withPage';
import WithKeyHandler from 'HOC/WithKeyHandler';
import useLanguage from 'hooks/useLanguage';
import useFunction from 'hooks/useFunction';

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setKeyHandler } from "Services/redux/app/actions";
import { KeyHandlerCode } from 'data/constants';
import { setOpenVideoPlayer } from 'Services/redux/video/actions';
import './search.css'

const searchItems = 5 * (window.screen.width > 1500 ? 5 : 4)

function Search(props) {
	const dispatch = useDispatch();
	const { defaultThumbnail, videos, carousels } = useSelector(({ app, entities }) => ({
		defaultThumbnail: app.graphic.defaultThumbnail,
		videos: entities.videos,
		carousels: entities.carousels
	}), shallowEqual);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const _videos = useMemo(() => Object.values(videos), [])
	const getWord = useLanguage();
	const [filteredArray, setFilteredArray] = useState(() => _videos.slice(0, searchItems)); // all the content to search in
	const [noMatch, setNoMatch] = useState(false); //when there are no matches
	const [noMatchText, setNoMatchText] = useState("");

	const playVideo = useCallback((activeIndex) => {
		if (activeIndex === null) return;

		const video = filteredArray[activeIndex].id

		dispatch(setOpenVideoPlayer({
			video,
			carouselId: "",
			content: [],
			backTo: KeyHandlerCode.GRID
		}));
	}, [dispatch, filteredArray]);

	const gridReachedUp = () => {
		const keyHandler = window.settings.platformSettings.keyboard ? KeyHandlerCode.KEYBOARD : KeyHandlerCode.SEARCH_INPUT

		dispatch(setKeyHandler(keyHandler));
	}

	const onBack = useCallback(() => {
		dispatch(setKeyHandler(KeyHandlerCode.MENU, filteredArray.length ? undefined : KeyHandlerCode.SEARCH_INPUT));
	}, [filteredArray, dispatch]);

	const handleInputChange = useCallback((searchTerm) => {
		const _searchTerm = searchTerm.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
		const regEx = new RegExp(_searchTerm, "i");
		const newArr = [];

		for (const video of _videos) {
			if (newArr.length >= searchItems) break;

			if (regEx.test(video.title))
				newArr.push(video);
		}

		for (const carouselId in carousels) {
			if (newArr.length >= searchItems) break;
			const carousel = carousels[carouselId];

			if (regEx.test(carousel.title)) {
				for (const videoId of carousel.videos) {
					newArr.push(videos[videoId]);
				}
			}
		}
		
		const noMatches = newArr.length === 0;

		if (noMatches) {
			setNoMatchText(`${getWord("searchNoResults")} '${searchTerm}'`);
			setFilteredArray(_videos.slice(0, searchItems));
		} else {
			setFilteredArray([...new Set(newArr)].slice(0, searchItems));
		}

		setNoMatch(noMatches);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [_videos]);

	const renderItem = useCallback((video) => {
		return <Thumbnail title={video.title} img={video.thumbnail_playlist || video.thumbnail} defaultThumbnail={defaultThumbnail} />;

	}, [defaultThumbnail]);

	const onGridItemClicked = useFunction(() => {
		dispatch(setKeyHandler(KeyHandlerCode.GRID))
	});

	return (
		<>
			{
				window.settings.platformSettings.keyboard
					? <Keyboard handleInputChange={handleInputChange} color={props.page.graphic.text_color} />
					: <Input
						handleInputChange={handleInputChange}
						color={props.page.graphic.text_color}
						length={filteredArray.length}
						onBack={onBack}
					/>
			}

			{noMatch && <p id="no_match"> {noMatchText} </p>}

			<div className={"search_grid" + (!props.isActive ? " grid_view_hidden" : "")} onClick={onGridItemClicked}>
				<Grid
					content={filteredArray}
					renderItem={renderItem}
					class="slider-item carousel_item"
					enter={playVideo}
					reachedUP={gridReachedUp}
					onBack={onBack}
				/>
			</div>
		</>
	)
}

export default WithPage(WithKeyHandler(Search, KeyHandlerCode.GRID), KeyHandlerCode.GRID)