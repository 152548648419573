// react
import React, { useState, useEffect, useRef, useCallback } from 'react';
import withControlRow from 'HOC/withControlRow';
import useKeyHandler from 'hooks/useKeyHandler';
import useFunction from 'hooks/useFunction';
// components
import NextVideo from '../nextVideo/NextVideo';
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
// services
import playersCenter from 'Services/Player/playersCenter';
import PauseIcon from "@material-ui/icons/Pause";
import { dataAttr } from 'utils/utiliesFunctions';
import "./playRow.css";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setVideo } from 'Services/redux/video/actions';

function PlayRow(props) {
	const dispatch = useDispatch();
	const { nextVideo, selectedVideo } = useSelector(({ video }) => {
		return {
			nextVideo: video.nextVideo,
			selectedVideo: video.selectedVideo
		}
	}, shallowEqual);

	const [isPlaying, setIsPlaying] = useState(true);
	const playBtnRef = useRef();

	const playNextVideo = useCallback(() => {
		dispatch(setVideo({
			video: nextVideo.id,
			carouselId: nextVideo.carousel
		}));
	}, [nextVideo, dispatch]);

	useEffect(() => {
		props.updateElem(playBtnRef.current);

		const onPlaying = () => setIsPlaying(true);
		const onPause = () => setIsPlaying(false);

		const onVideoChanged = () => {
			playersCenter.addEvents([
				["playing", onPlaying],
				["pause", onPause]
			])
		};

		onVideoChanged();

		// Run "onVideoChanged" function again after player got destroyed due to change of the content type [video <--> audio"]
		playersCenter.EventBus.add("VIDEO_CONTENT_TYPE_CHANGED", onVideoChanged);

		return () => {
			playersCenter.removeEvents([
				["playing", onPlaying],
				["pause", onPause]
			])

			playersCenter.EventBus.remove("VIDEO_CONTENT_TYPE_CHANGED", onVideoChanged);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedVideo]);

	useEffect(() => {
		const onVideoEnded = () => {
			playersCenter.addEvents([
				["ended", playNextVideo]
			]);
		}

		onVideoEnded();

		// Run "onVideoEnded" function again after player got destroyed due to change of the content type [video <--> audio]
		playersCenter.EventBus.add("VIDEO_CONTENT_TYPE_CHANGED", onVideoEnded);

		return () => {
			playersCenter.removeEvents([
				["ended", playNextVideo]
			]);

			playersCenter.EventBus.remove("VIDEO_CONTENT_TYPE_CHANGED", onVideoEnded);
		}

	}, [nextVideo, playNextVideo])

	useEffect(() => {
		props.updateElem(playBtnRef.current, props.isActive)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.isActive])

	const enter = () => {
		const elem = props.getElem();
		const action = dataAttr(elem, "action");

		switch (action) {
			case "togglePlay": playersCenter.controller("togglePlay"); break;
			case "playNext": playNextVideo(); break;
			default: break;
		}
	}

	const onMouseOver = useCallback(({ currentTarget }) => {
		props.onMouseOver({ currentTarget: currentTarget.parentElement });

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useKeyHandler({
		keys: { right: props.next, left: props.prev, enter },
		isActive: props.isActive,
		dependency: [nextVideo]
	})

	const onPlayerRowClicked = useFunction(enter);

	return (
		<div className={"controls" + (props.isActive ? " row_active" : "")}>

			<div className="controls_item" ref={playBtnRef} data-action="togglePlay" tabIndex={-1} aria-label={isPlaying ? "pause video" : "play video"}>
				<div className="playBtn centerlize video_UI_color_unactive"
					role="button"
					onMouseOver={onMouseOver}
					onClick={onPlayerRowClicked}
				>
					{isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
				</div>
			</div>

			<NextVideo mouseOver={onMouseOver} nextVideo={nextVideo} onClick={onPlayerRowClicked} />

		</div>
	)
}

export default withControlRow(React.memo(PlayRow))