import ChannelSettings from "utils/channelSettings/ChannelSettings";
import Beacons from "./beacons";
import getEventData from "./types"

class GlobalAnaltyics {
  setVideoSession(video) {
    Beacons.generateVideoSession(video);
  }

  /**
   * @description Sends events to all analytics plugins
   * @param {string} event the event type. must be exist in ./types.js
   * @param {object} eventData Data realted to the event
   * @param {string} type Which urls to fire (depends where we are sending the beacon)
  */
  sendEvent(event, eventData, type) {
    const data = getEventData(event, eventData);
    
    if (!data) return console.error("no valid event");

    const analyticsPlugins = ChannelSettings.getPlugins("analytics");
    Beacons.sendEvent(data.castify, type);

    if (analyticsPlugins)
      for (const plugin of analyticsPlugins) {
        try {
          plugin.sendEvent(data[plugin.name]);
        }
        catch (err) {
          console.warn(`Sending event for: ${plugin.name} failed`, err);
        }
      }
  }

  /**
   * @description Sends error events to all analytics plugins
   * @param {string} eventType The event type
   * @param {object} errorData Data realted to the event
   * @param {string} type Which urls to fire (depends where we are sending the beacon)
   */
  sendError(eventType, errorData, type) {
    const data = getEventData(eventType, errorData);
    const analyticsPlugins = ChannelSettings.getPlugins("analytics");

    if (!data) return console.error(`No valid error event ${eventType}`);

    Beacons.sendError(data.castify, type);

    if (analyticsPlugins)
      for (const plugin of analyticsPlugins) {
        try {
          plugin.sendError(data[plugin.name]);
        }
        catch (err) {
          console.warn(`Sending event for: ${plugin.name} failed`, err);
        }
      }
  }
}

export default new GlobalAnaltyics();