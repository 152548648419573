import React, { useEffect, useRef } from 'react'
import WithKeyHandler from 'HOC/WithKeyHandler';
import useKeyHandler from 'hooks/useKeyHandler';
import useLanguage from 'hooks/useLanguage';
import { KeyHandlerCode } from 'data/constants';
import { dataAttr } from 'utils/utiliesFunctions';

function PopupActionRow(props) {
  const getWord = useLanguage();
  const buttonsRef = useRef();
  const activeBtn = useRef();

  useEffect(() => {
    activeBtn.current = buttonsRef.current.firstChild;
    activeBtn.current.classList.add("active");
  }, [])

  const updateActiveElem = (elem) => {
    activeBtn.current.classList.remove("active");
    activeBtn.current = elem;
    activeBtn.current.classList.add("active");
    activeBtn.current.focus();
  }

  const onMouseOver = ({ target }) => {
    updateActiveElem(target);
  }

  const onEnter = () => {
    props.enter(dataAttr(activeBtn.current, "action"));
  }

  useKeyHandler({
    keys: {
      left: () => {
        const prevElem = activeBtn.current.previousElementSibling;
        if (!prevElem) return;

        updateActiveElem(prevElem);
      },
      right: () => {
        const nextElem = activeBtn.current.nextElementSibling;
        if (!nextElem) return;

        updateActiveElem(nextElem);
      },
      back: props.back,
      enter: onEnter
    },
    isActive: props.isActive
  });

  const getText = (label) => {
    const translatedWord = getWord(label)

    return translatedWord || label
  }

  return (
    <div className="popup_buttons" ref={buttonsRef}>
      {props.options.map((item, index) => (
        <div
          role="button"
          key={index}
          className={`popupAction-btn ${item.className}`}
          onMouseOver={onMouseOver}
          onClick={onEnter}
          data-action={item.action}
          tabIndex={-1}
        >
          {getText(item.label)}
        </div>
      ))}
    </div>
  )
}

export default WithKeyHandler(PopupActionRow, KeyHandlerCode.EXIT_POP_UP)
