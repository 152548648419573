import { createSelector } from 'reselect';

const initialState = {
  /**
   * @type {object} The pages the app has
   */
  pages: {},

  /**
   * @type {object} The carousels the app has (without restricted carouesls)
   */
  carousels: {},

  /**
   * @type {object} The videos the app has (without restricted videos)
   */
  videos: {}
};

/**
 * Entities reducer to interact with pages, video carouesl and more.
 * 
 * @param {object} state InitialState
 * @param {object} action Action object to update the state  
 */
export default function entitiesReducer(state = initialState, action) {
  switch (action.type) {
    case "init": return { ...state, ...action.payload }
    case "carousels/addVideo": {
      const { videoId, carouselId, field } = action.payload;
      const _carouselVideos = [...state.carousels[carouselId].videos];

      // check if the carousel does not have this video already
      if (!_carouselVideos.includes(videoId)) {
        _carouselVideos.push(videoId);
      }

      return {
        ...state,
        carousels: {
          ...state.carousels,
          [carouselId]: {
            ...state.carousels[carouselId],
            videos: _carouselVideos
          }
        },
        videos: {
          ...state.videos,
          [videoId]: {
            ...state.videos[videoId], ...field
          }
        }
      }
    }
    case "carousels/removeVideo": {
      const { videoId, carouselId, field } = action.payload;
      return {
        ...state,
        carousels: {
          ...state.carousels,
          [carouselId]: {
            ...state.carousels[carouselId],
            videos: [...state.carousels[carouselId].videos.filter((_videoId) => _videoId !== videoId)]
          }
        },
        videos: {
          ...state.videos,
          [videoId]: {
            ...state.videos[videoId], ...field
          }
        }
      }
    }
    default: return state;
  }
}

const _selectVideos = (videos) => videos;
const _selectVideos2 = (videos, contentId) => contentId;

export const selectVideos = createSelector(
  _selectVideos,
  _selectVideos2,
  (videos, content) => {

    const filteredVideos = content.reduce((total, current) => {
      return {
        ...total,
        [current]: videos[current]
      }
    }, {})


    return filteredVideos
  }
)