import React, { useCallback } from 'react'
import PopupActionRow from '../PopupActionRow';

import useLanguage from 'hooks/useLanguage';
import WithKeyHandler from 'HOC/WithKeyHandler';
import DeviceCenter from 'Services/platforms/deviceCenter';
import { KeyHandlerCode } from 'data/constants';
import "./exitPopup.css";

const buttons = [
	{ label: "no", action: "no" },
	{ label: "yes", action: "yes" }
];

function ExitPopUp(props) {
	const getWord = useLanguage();

	const closePopup = useCallback(() => {
		props.popupProperties.onClose && props.popupProperties.onClose();
		props.exitPopup();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.popupProperties, props.exitPopup]);

	const enter = useCallback((action) => {
		if (action === "yes")
			DeviceCenter.exitApp();
		else
			props.exitPopup();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [closePopup, props.exitPopup])

	return (
		<div className="exit_popup_holder">
			<h2 className="exit_title" id="dialogTitle">{getWord("exitText")}</h2>
			<p id="dialogDesc">no, press right for yes</p>

			<PopupActionRow options={buttons} back={closePopup} enter={enter} />
		</div>
	)
}

export default WithKeyHandler(ExitPopUp, KeyHandlerCode.EXIT_POP_UP)