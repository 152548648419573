// castify player v.1.1.1

import HlsPlayer from './hlsPlayer';
import IMA from './ima';
import { MediaAttributes, utility } from './utility';
import parser from 'utils/parser';
import Tracks from './tracks';
import Analytics from './Analytics';
import Config from './config';
import Ads from './ads';

/*
 data:
  type- audio / video default video
  target- where to build DOM
  video- video that playing right now
  loader - DOM, element to show when video is loading or buffering
  ads- ads settings / null for no ads
  events- video events
  callbacks- function to run on a certain time
  settings- player settings
  analytics- analytics library
  settings- settings
*/

/**
 * Castify player for playing video and audio files, supporting adaptive streaming, client side ads.
 * 
 * Castify player has access to playerCenter which gives you access to EventBus interface, the EventBus <br>
 * lets you connect with the UI thorugh events, the UI has access to the same playerCenter and register callbacks under an event name.<br>
 * then castify player can fire those event whenever needed.
 * 
 * @class
 * @version 1.0.0
 */
export default class CastifyPlayer {
  constructor(data, playerCenterRef) {
    /**
     * What kind of media we playing - can be video or audio
     */
    this.mediaType = data.type || "video";

    /**
     * Target element to append all elements to
     */
    this.target = utility.getTarget(data.target);

    /**
     * Media object currently playing.
     */
    this.currentVideo = data.video;

    /**
     * Object to store player configs
     */
    this.Config = new Config({ mediaType: data.type, device: data.device });

    /**
     * Analytics library to report player events and errors
     */
    this.Analytics = new Analytics(data.analytics);

    /**
     * Tracks Module to generate new track, toggle them and remove them
     */
    this.Tracks = new Tracks(this, data.settings.encoding, data.settings.subtitles);

    /**
     * Module to handle ads request and response
     */
    this.Ads = new Ads(data.ads, this);

    /**
     * Extension to help on platforms which can't play hls streams
     */
    this.HLS_player = null;

    this.cutsomEvents = data.events; // html5 video/audio events
    this.callbacks = data.callbacks; // function to run
    this.videoSessionStarted = false;

    /**
     * The html media element
     */
    this.mediaRef = null;

    /**
     * The html source element
     */
    this.sourceRef = null;

    /**
     * The wrapper element
     */
    this.wrapperRef = null;

    /**
     * The loader element
     */
    this.loaderRef = data.loader;

    /**
     * Player center ref
     */
    this.playerCenterRef = playerCenterRef;

    this.initPlayer();
    this.printPlayerData();
  }

  /**
   * Starting point of castiy player create DOM nodes, build events object and get the ad manager
   */
  initPlayer() {
    // Create object which holds reference to our events so we can remove them later
    this.buildPlayerEvents();
    // create the html nodes we need, attach events and set the source
    this.createPlayer();
    // attach ad manager to the ads module in that way we can work with multiple ads services, the default is IMA
    this.Ads.setAdManager(this._getAdManager("ima"));
    this.Analytics.sendEvent("startVideo");
  }

  /**
   * Remove all events, remove the source safely
   */
  cleanup() {
    // remove the video safely without emitting errors
    this.removeBindEvents();
    this.Ads.cleanup();
    this.pause();

    if (this.Config.getConfig("currentPlayerType") === "hls_extension") {
      this.HLS_player.cleanup();
      this.mediaRef.removeAttribute("src");
    }
    else
      this.sourceRef.removeAttribute("src");

    this.load();
    this.Analytics.sendEvent("endVideo");
  }

  /**
   * Print player data - just in dev mode
   */
  printPlayerData() {
    if (process.env.NODE_ENV !== "production") {
      console.log({
        currentVideo: this.currentVideo,
        ads: this.Ads,
        config: this.Config
      });

      console.log(this.mediaRef);
    }
  }

  /**
   * Get the desired ad manager
   * 
   * @param {string} adService String represent the ad library to load.
   * 
   * @returns adManager instance. 
   */
  _getAdManager(adService) {
    switch (adService) {
      case "ima": return IMA
      default: throw new Error("Unknown adService");
    }
  }

  /**
   * Create DOM nodes, set the source and the player type
   */
  createPlayer = () => {
    this.setPlayerType();
    this.createNodes();
    this.bindEvents();
    this.setSource();
  }

  /**
   * Create all the html nodes we need, assign attributes and atach them to the DOM.
   */
  createNodes() {
    this.wrapperRef = document.createElement("div");
    this.buildMediaElements();
    this.target.appendChild(this.wrapperRef);
  }

  buildMediaElements() {
    this.mediaRef = document.createElement(this.mediaType);
    this.sourceRef = document.createElement("source");

    // assign attributes to our video|audio player like class, id
    Object.assign(this.mediaRef, new MediaAttributes());

    // if the current device cant play hls videos, add the source to the media element
    !this.Config.getConfig("useHls") && this.mediaRef.appendChild(this.sourceRef);

    this.wrapperRef.appendChild(this.mediaRef);
    this.wrapperRef.classList.add("player_wrapper", this.mediaType);
  }

  /**
   * Check if need to use hls.js to player hls content by setting the current video.
   */
  setPlayerType() {
    const currentPlayerType = utility.getPlayerType(this.currentVideo, this.Config.getConfig("useHls"));

    this.Config.setConfig({ currentPlayerType });
  }

  bindEvents() {
    if (this.Config.getConfig("currentPlayerType") === "hls_extension") {
      this.addEvents([...this.playerEvents.video, ...this.playerEvents.source], "video");
    } else {
      this.addEvents(this.playerEvents.video, "video");
      this.addEvents(this.playerEvents.source, "source");
    }
  }

  removeBindEvents() {
    if (this.Config.getConfig("currentPlayerType") === "hls_extension") {
      this.removeEvents([...this.playerEvents.video, ...this.playerEvents.source], "video");
    } else {
      this.removeEvents(this.playerEvents.video, "video");
      this.removeEvents(this.playerEvents.source, "source");
    }
  }

  buildPlayerEvents = () => {
    this.playerEvents = {
      video: [
        ["loadstart", this.onLoadStarts],
        ["loadeddata", this.onLoadedData],
        ["error", this.onError],
        ["play", this.onPlay],
        ["playing", this.onPlaying],
        ["pause", this.onPause],
        ["waiting", this.onWaiting],
        ["seeking", this.displayLoader],
        ["seeked", this.hideLoader],
        ["ended", this.onVideoEnded]
      ],
      source: [
        ["error", this.onError]
      ]
    };
  }

  /**
   * Change video source.<br>
   * Will notify Tracks and Analytics modules that a video has changed.
   */
  async setSource() {
    this.Analytics.setVideoSession(this.currentVideo);

    await this.Tracks.onVideoChanged(this.currentVideo.captions);

    const src = this.getVideoSource();
    const type = utility.getMimeType(this.currentVideo);

    // only platforms using hls_extension and when video is hls
    if (this.Config.getConfig("currentPlayerType") === "hls_extension") {
      // initialize the hls extenstion if not done before
      if (!this.HLS_player) {
        this.HLS_player = new HlsPlayer(this);
      }

      return this.HLS_player.playVideo();
    }

    // for the platforms which don't need HLS.js
    if (this.Config.getConfig("useHls")) {
      Object.assign(this.mediaRef, { src });
    } else {
      Object.assign(this.sourceRef, { src, type });
    }

    // start the video in a certain position
    this.seekTo(this.currentVideo.currentTime || 0);
    this.mediaRef.load();
  }

  /**
   * Change the current playing video, return true if the new video has the same id as the old one.<br>
   * Also, check if we need to change player type i.e: audio to video or vice versa.<br>
   * If thats the case destroy the media element and rebuild it.<br>
   * 
   * @param content the new content object we are trying to play.
   * 
   * @returns true or void.
   */
  changeStream = (content = {}) => {
    // check if we are trying to play the same content- do nothing
    if (content.id === this.currentVideo.id) return true;

    // if the new content has different content type (audio or video)
    const isMediaTypeDifferent = content.content_type !== this.currentVideo.content_type;

    // update the current video
    this.currentVideo = content;

    // update the media type
    this.mediaType = content.content_type || "video";

    // Reset all ads timer and countdowns
    this.Ads.resetValues();

    this.setPlayerType();
    this.displayLoader();

    // if the current content has a different content_type than the new content destroy the player and build again.
    if (isMediaTypeDifferent) {
      // this will remove the media element from the dom with all of the events and will remove class from wrapperRef
      this.mediaRef.remove();
      this.wrapperRef.removeAttribute("class");
      // build media element again
      this.buildMediaElements();
      // bind events again
      this.bindEvents();

      // Run all events which registred to run after player changed the content type.
      this.playerCenterRef.EventBus.run("VIDEO_CONTENT_TYPE_CHANGED", this.currentVideo);
    }

    this.setSource();
  }

  /**
   * Display the loader.
   * 
   * @param {boolean} [transparentBackground = false] whether or not display the loader with transparent background
   */
  displayLoader = (transparentBackground = false) => {
    const className = transparentBackground ? "seek" : "visible";
    this.loaderRef.removeAttribute("class"); //remove all classes
    this.loaderRef.classList.add(className); // add only the class you need
  }

  /**
   * Hide the loader 
   */
  hideLoader = () => {
    this.loaderRef.removeAttribute("class"); //remove all classes
    this.loaderRef.classList.add("hidden"); // add class hidden
  }

  /**
   * Run events coming from videoPlayer.js component
   * 
   * @param {string} event The name of the event to run
   * @param {object} data event related data to send with the event
   */
  runParentEvent(event, data) {
    const _event = this.cutsomEvents[event];
    _event && _event(data);
  }

  /**
   * Run callbacks coming from videoPlayer.js component
   * 
   * @param {string} callback The name of the callback to run
   * @param {object} data event related data to send with the callback
   */
  runParentCallBack(callback, data) {
    // check if you have this callback before you run it
    const _callback = this.callbacks[callback];
    _callback && _callback(data);
  }

  /**
   * Bind a html5 event to _target
   * 
   * @param {string} _events The event name
   * @param {string} _target The target to bind the event to (source or media ref)
   */
  addEvents(_events, _target = "video") {
    const target = _target === "video" ? this.mediaRef : this.sourceRef;

    for (const [type, callback] of _events)
      callback && target.addEventListener(type, callback);
  }

  /**
   * Unbind a html5 event from _target
   * 
   * @param {string} _events The event name
   * @param {string} _target The target to remove the event from (source or media ref)
   */
  removeEvents(_events, _target = "video") {
    const target = _target === "video" ? this.mediaRef : this.sourceRef;

    for (const [type, callback] of _events)
      callback && target.removeEventListener(type, callback);
  }

  /* === ACTIONS === */

  /**
   * Load/ refresh stream src.
   */
  load = () => this.mediaRef.load()

  /**
   * Play the video.
   */
  play = () => this.mediaRef.play();

  /**
   * Pause the video.
   */
  pause = () => this.mediaRef.pause();

  /**
   * toggle play / pause.
   * 
   * @returns {string} the action which made
   */
  togglePlay = () => {
    // check if the video is playing
    const isPlaying = this.isPlaying();

    isPlaying ? this.pause() : this.play();

    // if isPlaying is 'true' return the new video state 
    // i.e: if the video is playing, pause it and then send the new state (pause)
    return isPlaying ? "pause" : "playing"
  }

  /**
   * Replay the stream.
   */
  replay = () => {
    this.seekTo(0);
    this.play();
  };

  /**
   * Stop the video- will go to the beginning of the video.
   */
  stop() {
    this.seekTo(0);
    this.pause();
  };

  /**
   * Jump to a new position.
   * 
   * @param {number} time New time to jump to. 
   * 
   * @returns the new current time.
   */
  seekTo = time => this.mediaRef.currentTime = time;

  /**
   * Check if media is playing.
   * 
   * @returns {boolean} If the video is playing
   */
  isPlaying = () => !this.mediaRef.paused;

  /**
   * Get the current time of the stream.
   * 
   * @returns {number} Stream current time.
   */
  getCurrentTime = () => this.mediaRef.currentTime;

  /**
   * Get video duration.
   * 
   * @returns {number} currently video duration.
   */
  getDuration = () => this.mediaRef.duration || 0;

  /**
   * Get refernece to the HTML media element.
   * 
   * @returns The media element (video | audio)
   */
  getMediaRef = () => this.mediaRef;

  /**
   * Get the current media playing.
   * 
   * @returns The currnt media object playing.
   */
  getVideo = () => this.currentVideo;

  /**
   * Change macros for the current stream url (does not change the real url).
   * 
   * @returns Parsed stream url.
   */
  getVideoSource = () => {
    return parser.parse(this.currentVideo.streamURL, {}, false);
  }

  /* === TRACKS === */

  /**
   * Change the current playing track.
   * 
   * @param {string} trackLang The ISO language of the text track we want to change to.
   */
  setTrack = async (trackLang) => {
    await this.Tracks.changeTrack(trackLang);
  }

  /**
   * Toggle tracks visibility or use the default track.
   * 
   * @param {boolean} isActive use default track (the first caption) or hide it.
   */
  toggleTracks = async (isActive) => {
    this.Tracks.setDispalyTracks(isActive);
    await this.Tracks.changeTrack(isActive ? "default" : "off");
  }

  /* === EVENTS === */

  /**
   * Fires when the player starts loading the stream. 
   *
   * @event Video#loadStarts
   */
  onLoadStarts = () => {
    this.runParentEvent("loadstart", this.currentVideo);
  }

  /**
   * Fires when the player finished to load the stream.
   *
   * @event Video#loaded
   */
  onLoadedData = () => {
    this.runParentEvent("loadeddata");
    this.play();

    if (!this.videoSessionStarted) {
      setTimeout(() => this.Ads.requestAd("preRoll"), 3500);
      this.videoSessionStarted = true;
      this.runParentCallBack("onInit", this.currentVideo);
    }
  }

  /**
   * Fires when there is an error with the player. 
   *
   * @event Video#error
   */
  onError = (e) => {
    this.Analytics.sendError("videoError", {
      videoId: this.currentVideo.id,
      url: this.currentVideo.streamURL,
    });

    console.error("Video Error");

    console.dir({
      url: this.currentVideo,
      config: this.Config
    });

    console.dirxml(this.mediaRef);
    this.runParentEvent("error");
  }

  /**
   * Fires when play requested. 
   *
   * @event Video#play
   */
  onPlay = () => {
    this.Analytics.sendEvent("play");
  }

  /**
   * Fires when the player is actually playing.
   *
   * @event Video#playing
   */
  onPlaying = () => {
    this.hideLoader();
    this.runParentEvent("playing");
  }

  /**
   * Fires when the player is paused.
   *
   * @event Video#paused
   */
  onPause = () => {
    this.Analytics.sendEvent("pause");
    this.runParentEvent("pause");
  }

  /**
   * Fires when player is buffering the next frame.
   *
   * @event Video#waiting
   */
  onWaiting = () => {
    this.displayLoader(true);
  }

  /**
   * Fires when video has ended.
   *
   * @event Video#ended
   */
  onVideoEnded = () => {
    this.runParentEvent("ended");
    this.displayLoader();
    this.Analytics.sendEvent("endVideo");
    // this.Ads.requestAd("postRoll", this.rawEvents.ended);
  }

  /**
   * Function to run before ads starts, pause, remove the events, display loader, and call adStarts event. 
   */
  onAdBreakStarted = () => {
    this.pause();
    this.displayLoader();
    this.removeBindEvents();

    if (this.Config.getConfig("currentPlayerType") === "hls_extension") {
      this.HLS_player && this.HLS_player.onAdBreakStarted();
    }

    this.Tracks.adStarted()
    this.runParentCallBack("adStarts");
  }

  /**
   * Function to run when all ads ended. Bind the events again, and call adFinished event 
   */
  onAdBreakFinished = () => {
    this.displayLoader(); // display loader first
    // run ad break callbacks 

    this.bindEvents();
    this.runParentCallBack("adFinished");

    this.Tracks.adFinished(this.currentVideo.captions);
    // if the current player is hls_extension let it handle to return from ads by itself
    if (this.Config.getConfig("currentPlayerType") === "hls_extension") {
      this.HLS_player && this.HLS_player.onAdBreakFinished(this.currentVideo);
    } else {
      this.play();
    }
  }
}