import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';

// components
import Playlist from './Playlist';
import AgeConfirmation from 'components/popups/parentalControl/AgeConfirmation';

// services & utilis
import voiceReader from 'utils/voiceReader';
import { dataAttr } from 'utils/utiliesFunctions';
import { KeyHandlerCode } from 'data/constants';

// redux stuff
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { checkVideoIsSensitive, getVideo } from 'Services/redux/reduxFunction';
import { setActiveCarouselManager, setKeyHandler } from 'Services/redux/app/actions';
import { setOpenVideoPlayer } from 'Services/redux/video/actions';
import { createSelector } from 'reselect';

// react stuff
import useKeyHandler from 'hooks/useKeyHandler';
import WithKeyHandler from 'HOC/WithKeyHandler';
import usePopup from 'hooks/usePopup';
import PropTypes from 'prop-types';

import "./carouselManager.css";

// when can have a variable to kkep track on the current location and request more if we are closing to the end, and also removed the elements we passed

const activeVideoClass = "active", activeCarouselClass = "active_carousel";
let changeTransitionTimeout;
const scrollAmountByScreenSize = {
  clicksLeft: (window.innerWidth > 1500 ? 5 : 4),
  leftScroll: (window.innerWidth > 1500 ? 4 : 3)
}

const makeSelectCarouesls = () =>
  createSelector(
    carousels => carousels,
    (_, contentList) => contentList,
    (carousels, contentList) => {
      return contentList.reduce((total, current) => {
        return {
          ...total,
          [current]: carousels[current]
        }
      }, {})
    }

  )

function CarouselManager (props) {
  const dispatch = useDispatch();
  const selectCarouesls = useMemo(makeSelectCarouesls, []);

  const { carousels, rtl } = useSelector(({ entities, app }) => ({
    rtl: app.graphic.rtl,
    carousels: selectCarouesls(entities.carousels, props.content)
  }), shallowEqual);

  const { displayPopup } = usePopup();

  // const allCarousels = useMemo(() => {
  //   const arr = [];

  //   for (const index in props.content) {
  //     const contentId = props.content[index];
  //     const prevElem = arr[index - 1]

  //     arr.push({
  //       id: contentId,
  //       index,
  //       height: 311,
  //       top: prevElem ? prevElem.top + 311 : 0
  //     })
  //   }

  //   return arr;
  // }, []);

  // const [displaycarousel, setDisplaycarousel] = useState(() => allCarousels.splice(0, 5))
  const [lazy, setLazy] = useState(4);

  const carouselScrollRef = useRef(); // the div which scroll vertically 
  const activeCarouselRef = useRef(); // the active carousel
  const carouselScrollAreaRef = useRef(); // the div which needs to be scroll horizntally
  const activeVideoRef = useRef(); // active video div
  const verticalScrollVal = useRef(0); //the verticall scroll amount
  const changeVideoActiveTimeout = useRef(); // timeout to run to change the active video (detailed/ live)
  const carouselsData = useRef({});

  const wheelTimeout = useRef();
  const wheelThrottle = useRef();

  useEffect(() => {
    dispatch(setActiveCarouselManager(props.type));

    return () => {
      clearTimeout(changeVideoActiveTimeout.current);
      clearTimeout(wheelTimeout.current);
      clearTimeout(changeTransitionTimeout);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const firstCarousel = carouselScrollRef.current.firstChild;

    // == scroll up ==

    // everytime the content has changed (i.e category changed) set active the first video and carousel
    Object.assign(carouselScrollRef.current.style, {
      transition: "none",
      transform: "translate3d(0,0,0)"
    })

    changeTransitionTimeout = setTimeout(() => {
      carouselScrollRef.current.style.transition = "transform .35s linear";
    }, 200);

    verticalScrollVal.current = 0;
    verticalScroll(0);

    setActiveCarousel(firstCarousel);
    setActiveElem(firstCarousel.querySelector(".slider-item"));

    carouselsData.current = props.content.reduce((total, current) => {
      return {
        ...total, [current]: {
          pos: 0,
          scroll: 0,
          clicksLeft: carousels[current] ? carousels[current].videos.length - scrollAmountByScreenSize.clicksLeft : 0
        }
      }
    }, {})

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.content]);

  useEffect(() => {
    if (!activeVideoRef.current) return;
    // toggle video class and style if the carouselManager is active or not
    if (props.isActive) {
      activeVideoRef.current.classList.add(activeVideoClass);
      activeVideoRef.current.style.color = dataAttr(activeCarouselRef.current, "activeColor");
    } else {
      activeVideoRef.current.classList.remove(activeVideoClass);
      activeVideoRef.current.style.color = "inherit";
    }
  }, [props.isActive]);

  const horizntalScroll = (value) => {
    const width = +dataAttr(activeCarouselRef.current, "width");
    const caruoselData = carouselsData.current[dataAttr(activeCarouselRef.current, "carouselId")];

    // when we click right (increase) we need to get far from zero
    // when click left (decrease) we need to back to zero
    if (value === "increase") {
      caruoselData.scroll += width;
    } else {
      caruoselData.scroll -= width;

      // make sure we not exceed zero some how
      if (caruoselData.scroll < 0) {
        caruoselData.scroll = 0;
      }
    }

    // if the app is ltr we need to scroll in the negative direction 
    const scrollTo = rtl ? caruoselData.scroll : -caruoselData.scroll;
    carouselScrollAreaRef.current.style.transform = `translate3d(${scrollTo}px,0 ,0)`;
  }

  const verticalScroll = (amount) => {
    verticalScrollVal.current += amount;

    // if (verticalScrollVal.current > 0) {
    //   verticalScrollVal.current = 0;
    // }

    carouselScrollRef.current.style.transform = `translate3d(0,${verticalScrollVal.current}px ,0)`
  }

  const setActiveElem = (elem) => {
    clearTimeout(changeVideoActiveTimeout.current);

    // if we already have current item
    if (activeVideoRef.current) {
      activeVideoRef.current.classList.remove(activeVideoClass);
      activeVideoRef.current.style.color = "inherit";
    };

    if (elem) {
      voiceReader.read(elem.innerText.split("\n").slice(-1).join(""));
      activeVideoRef.current = elem;
      if (props.isActive) {
        activeVideoRef.current.classList.add(activeVideoClass);
        activeVideoRef.current.style.color = dataAttr(activeCarouselRef.current, "activeColor");
      }

      changeVideoActiveTimeout.current = setTimeout(() => {
        props.onItemSelected && props.onItemSelected(getVideo({
          videoId: dataAttr(elem, "videoId"),
          caroueslId: dataAttr(elem.parentElement, "carouselId")
        }));
      }, 600);
    }
  }

  const setActiveCarousel = elem => {
    if (activeCarouselRef.current === elem) return
    activeCarouselRef.current && activeCarouselRef.current.classList.remove(activeCarouselClass);
    activeCarouselRef.current = elem;
    activeCarouselRef.current.classList.add(activeCarouselClass);
    carouselScrollAreaRef.current = elem.lastElementChild.firstChild;
  }

  const up = (fromWheel) => {
    const prevActiveCarousel = activeCarouselRef.current.previousElementSibling;

    // if there are no previous element
    if (!prevActiveCarousel) {
      if (fromWheel !== true)
        props.reachedTop && props.reachedTop();
      return;
    };

    // const newItem = allCarousels.pop();
    // const firstItem = displaycarousel[0]

    // const _carousel = [{ ...newItem, top: firstItem.top - newItem.height }, ...displaycarousel];    
    // const a = _carousel.splice(0,6);
    // allCarousels.unshift(..._carousel)
    // setDisplaycarousel(a);

    const height = +dataAttr(prevActiveCarousel, "height");
    const { videoPos } = getCarouselPos(prevActiveCarousel);

    verticalScroll(height);
    setActiveCarousel(prevActiveCarousel);
    setActiveElem(carouselScrollAreaRef.current.children[videoPos]);
  }

  const down = () => {
    // get the next carousel element
    const nextActiveCarousel = activeCarouselRef.current.nextElementSibling;

    // if there is no next element call a custon function (if passed) and finish the function
    if (!nextActiveCarousel) {
      props.reachedBottom && props.reachedBottom();
      return;
    };

    // const newItem = allCarousels.shift();
    // const _carousel = [...displaycarousel, newItem];
    // const a = _carousel.splice(-6);
    // const leftItem = _carousel[0];
    // const lastItem = allCarousels[allCarousels.length - 1];
    // if (leftItem)
    //   allCarousels.push({ ...leftItem, top: lastItem.top + lastItem.height })
    // setDisplaycarousel(a);
    setLazy(state => state + 1);

    // get the number of carousel we currently *displaying* 
    const { videoPos } = getCarouselPos(nextActiveCarousel);

    // scroll carousel down
    verticalScroll(-dataAttr(activeCarouselRef.current, "height"));
    setActiveCarousel(nextActiveCarousel);
    setActiveElem(carouselScrollAreaRef.current.children[videoPos]);
  }

  const onMouseEnterVideo = useCallback(({ currentTarget }) => {
    setActiveCarousel(currentTarget.parentElement.parentElement.parentElement);
    setActiveElem(currentTarget);

    const { id } = getCarouselPos(currentTarget.parentElement.parentElement.parentElement);
    carouselsData.current[id].pos = +dataAttr(currentTarget, "index");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isActive]);

  const onWheel = event => {
    if (wheelThrottle.current === false) return

    wheelThrottle.current = false;

    wheelTimeout.current = setTimeout(() => {
      wheelThrottle.current = true
    }, 200);

    if (event.deltaY < 0)
      up(true);
    else
      down();
  }

  const mouseEnter = () => {
    dispatch(setKeyHandler(KeyHandlerCode.CAROUSEL));
  }

  const getCarouselPos = (elem) => {
    const id = dataAttr(elem, "carouselId")
    const carouselData = carouselsData.current[id];

    return {
      id,
      carouselIndex: +dataAttr(elem, "carouselIndex"),
      videoPos: carouselData.pos,
      max: +dataAttr(elem, "length")
    }
  }

  const selectItem = useCallback(() => {
    const { videoPos, id } = getCarouselPos(activeCarouselRef.current);
    const videoId = carousels[id].videos[videoPos];

    if (!videoId) return;

    // if enter props exist run it instead run the default action (start Video) open video page and play the video
    if (props.enter) {
      return props.enter(videoId, id)
    }

    if (!window.settings.appSettings.approved_sensitive_content && checkVideoIsSensitive(videoId)) {
      displayPopup(AgeConfirmation)
    }

    // default action for enter is to open the player page
    dispatch(setOpenVideoPlayer({
      video: videoId,
      carouselId: id,
      content: props.content,
      backTo: KeyHandlerCode.CAROUSEL
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.content]);

  useKeyHandler({
    keys: {
      right: () => {
        if (!activeVideoRef.current) return;

        const { id, videoPos, max } = getCarouselPos(activeCarouselRef.current);
        const nextActiveElem = activeVideoRef.current.nextElementSibling;
        //const shouldScroll =  videoPos + 5 < max;
        const caroselData = carouselsData.current[id];

        if (caroselData.clicksLeft > 0) {
          horizntalScroll("increase");
          caroselData.clicksLeft -= 1;
        }

        if (!nextActiveElem) return;

        if (max > videoPos) {
          caroselData.pos += 1;
        }

        setActiveElem(nextActiveElem);
      },
      left: () => {
        if (!activeVideoRef.current) {
          props.reachedLeft && props.reachedLeft();
          return;
        }

        const { videoPos, max, id } = getCarouselPos(activeCarouselRef.current);
        const prevActiveElem = activeVideoRef.current.previousElementSibling;
        const shouldScroll = videoPos < max - scrollAmountByScreenSize.leftScroll;
        const caroselData = carouselsData.current[id];

        if (shouldScroll) {
          if (max - scrollAmountByScreenSize.clicksLeft > caroselData.clicksLeft)
            caroselData.clicksLeft += 1;
          horizntalScroll("decrease");
        }

        if (!prevActiveElem) {
          props.reachedLeft && props.reachedLeft();
          return;
        }

        if (videoPos > 0) {
          caroselData.pos -= 1;
        }

        setActiveElem(prevActiveElem);
      },
      up,
      down,
      enter: selectItem,
      back: () => dispatch(setKeyHandler(KeyHandlerCode.MENU))
    },
    dependency: [lazy],
    isActive: props.isActive,
    debounce: 250
  });

  const playlistsDisplay = props.content.slice(0, lazy).map(playlistId => {
    const playlist = carousels[playlistId];
    return playlist.display ?
      <Playlist
        key={ playlist.entity_id }
        carouselId={ playlistId }
        onMouseEnterVideo={ onMouseEnterVideo }
        onClick={ selectItem }
        carouselHeader={ props.carouselHeader }
        carouselManagerType={ props.carouselManagerType }
      /> : null
  })

  return (
    <div className={ `managerWrapper ${props.isActive ? "activeCarouselManager" : ""}` } onWheel={ onWheel } onMouseEnter={ mouseEnter }>
      <div className="managerScrollWrapper" ref={ carouselScrollRef }>
        { playlistsDisplay }
      </div>
    </div>
  )
}

export default WithKeyHandler(CarouselManager, "carouselManager");

CarouselManager.propTypes = {
  content: PropTypes.array.isRequired, //content to render
  reachedTop: PropTypes.func, // function to run when reaching the most top carousel and pressing up
  reachedBottom: PropTypes.func, // function to run when reaching the most bottom carousel and pressing down
  reachedLeft: PropTypes.func // function to run when reaching the most left carousel item and pressing left
}