import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

// services
import { KeyHandlerCode } from 'data/constants';
import useKeyHandler from 'hooks/useKeyHandler.js';
import { navigateDown, navigateLeft, navigateRight, navigateUp } from './navigation.js';
import { setKeyHandler } from 'Services/redux/app/actions.js';
import { dataAttr } from 'utils/utiliesFunctions';
import WithKeyHandler from 'HOC/WithKeyHandler.js';
import { data } from "./data.js";

import "./keyboard.css";

function Keyboard(props) {
  const dispatch = useDispatch()
  const [keyType, setKeyType] = useState("letter");
  const keyboardRef = useRef();
  const inputRef = useRef();

  const writeWithKeyboard = useCallback((action) => {
    switch (action) {
      case "clear": inputRef.current.innerHTML = ""; break;
      case "backspace": inputRef.current.innerHTML = inputRef.current.innerText.slice(0, inputRef.current.innerText.length - 1); break;
      case "search": dispatch(setKeyHandler(KeyHandlerCode.GRID)); break;
      default: //type
        inputRef.current.innerHTML += action;
    }

    props.handleInputChange(inputRef.current.innerText);
  }, [dispatch, props]);


  useEffect(() => {
    const mobileRemoteKeyDown = (event) => {
      switch (event.keyCode) {
        case 8:
          writeWithKeyboard("backspace")
          break;
        default:
          if (
            event.keyCode === 32 || event.keyCode === 92 ||
            (event.keyCode >= 44 && event.keyCode <= 90) ||
            (event.keyCode >= 160 && event.keyCode <= 171) ||
            (event.keyCode >= 174 && event.keyCode <= 176) ||
            (event.keyCode >= 219 && event.keyCode <= 222)
          )
            writeWithKeyboard(event.key)
          break;
      }
    }
    if (window.settings.platform === "vizio")
      window.addEventListener("keydown", mobileRemoteKeyDown);

    return () => {
      if (window.settings.platform === "vizio")
        window.removeEventListener("keydown", mobileRemoteKeyDown);
    }
  }, [writeWithKeyboard])


  useEffect(() => {
    if (props.isActive) {
      const elem = keyboardRef.current.firstChild;
      elem.classList.add("active");
      elem.focus();
    }
  }, [props.isActive])

  const enter = () => {
    const action = dataAttr(document.querySelector(".btn.active"), "action");

    switch (action) {
      case "symbols": setKeyType(state => state === "number" ? "letter" : "number"); break;
      default: //type
        writeWithKeyboard(action)
    }
  }

  useKeyHandler({
    keys: {
      down: () => {
        const res = navigateDown();
        res === "goToGrid" && dispatch(setKeyHandler(KeyHandlerCode.GRID))
      },
      right: navigateRight,
      left: navigateLeft,
      up: navigateUp,
      enter,
      back: () => dispatch(setKeyHandler(KeyHandlerCode.MENU))
    },
    isActive: props.isActive
  });

  const keyboardMemo = useMemo(() => {
    return (
      data.keys.map((item, index) => (
        <div
          key={index}
          data-index={index}
          className={`btn ${item.className || ""}`}
          data-action={item.action || item[keyType]}
          tabIndex={-1}
          aria-label={item.label || item[keyType]}
        >
          <div className="centerlize">
            {
              item.display
                ? <item.display />
                : item[keyType]
            }
          </div>
        </div>
      ))
    )
  }, [keyType])

  return (
    <div className="keyBoardContainer">
      <div className="searchFields" ref={inputRef} style={{ borderColor: `3px solid ${props.color}` }} />

      {props.isActive && (
        <div className="keyboardWrapper">
          <div className="keyboard" ref={keyboardRef}>
            {keyboardMemo}
          </div>
        </div>
      )}

    </div>
  )
}

export default WithKeyHandler(Keyboard, KeyHandlerCode.KEYBOARD)