import { keyCodes } from "data/keyCodes";
import { addKeyHandler } from "utils/utiliesFunctions";
import parser from "utils/parser";

export default class IMA {
	constructor(data) {
		if (!IMA.checkAvailability()) throw new Error("IMA couldn't be loaded");

		this.player = data.player; // castifyPlayer reference
		this.vastUrl = data.vast;
		this.adsCallbacks = data.callbacks; // events to run when ads are failed / ended
		this.currentAdBreak = null;

		// ui components
		this.countdownTimer = null;
		this.timerUI = null;
		this.adContainer = null;

		// build the UI
		this.buildDOM();
		// ad components
		this.adsManager = null;
		this.adsRenderingSettings = null;

		this.adDisplayContainer = new window.google.ima.AdDisplayContainer(this.adContainer);
		this.adsLoader = new window.google.ima.AdsLoader(this.adDisplayContainer); // object request ads form ad servers
		this.adsLoader.addEventListener(window.google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED, this.onAdsManagerLoaded, false, this);
		this.adsLoader.addEventListener(window.google.ima.AdErrorEvent.Type.AD_ERROR, this.onAdError, false, this);
	}

	static checkAvailability() {
		return window.google !== undefined;
	}

	cleanup() {
		if (this.adsLoader)
			this.adsLoader.destroy();

		if (this.adsManager)
			this.adsManager.destroy();
	}

	buildDOM() {
		this.adContainer = document.createElement("div");
		this.timerUI = document.createElement("div");

		this.adContainer.id = "adContainer";
		this.timerUI.id = "adTimer";

		this.adContainer.appendChild(this.timerUI);
		this.player.wrapperRef.appendChild(this.adContainer);
	}

	regeisterKeys() {
		const keyDownEvent = ({ keyCode }) => {
			switch (keyCode) {
				case keyCodes.PAUSE: this.adsManager.pause(); break;
				case keyCodes.PLAY: this.adsManager.resume(); break;
				default: break;
			}
		}

		addKeyHandler(keyDownEvent);
	}

	onAdsManagerLoaded(adsManagerLoadedEvent) {
		this.adsRenderingSettings = new window.google.ima.AdsRenderingSettings();
		this.adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true;

		this.adsManager = adsManagerLoadedEvent.getAdsManager(this.player.getMediaRef(), this.adsRenderingSettings);

		const adEvent = [
			["CONTENT_PAUSE_REQUESTED", this.onContentPauseRequested],
			["CONTENT_RESUME_REQUESTED", this.onContentResumeRequested],
			["STARTED", this.onStarted],
			["IMPRESSION", this.adsCallbacks.onImpression],
			["COMPLETE", this.onAdEnded],
			["AD_BUFFERING", this.player.displayLoader],
			["AD_CAN_PLAY", this.player.hideLoader],
			["FIRST_QUARTILE", this.adsCallbacks.onFirstQuartile],
			["THIRD_QUARTILE", this.adsCallbacks.onThirdQuartile]
		]

		for (const [type, action] of adEvent)
			this.adsManager.addEventListener(window.google.ima.AdEvent.Type[type], action, false);

		// handle errors
		this.adsManager.addEventListener(window.google.ima.AdErrorEvent.Type.AD_ERROR, this.onAdError, false, this);

		try {
			const mediaRef = this.player.getMediaRef();
			const { clientWidth: width, clientHeight: height } = mediaRef;
			this.adsManager.init(width, height, window.google.ima.ViewMode.NORMAL);
			this.adsManager.start();
		} catch (adError) {
			console.log("Ad manager could not be started");
			this.onAdError();
		}
	}

	requestAd(adBreak) {
		this.currentAdBreak = adBreak;
		this.adDisplayContainer.initialize();

		const macros = {
			"[VIDEO_TITLE]": this.player.currentVideo.title,
			"[VIDEO_CONTENT_ID]": this.player.currentVideo.id
		}

		const adsURL = parser.parse(this.vastUrl, macros); // get ad url after macro changes
		const mediaElem = this.player.getMediaRef();

		const adsRequest = new window.google.ima.AdsRequest();
		adsRequest.adTagUrl = adsURL;
		adsRequest.linearAdSlotWidth = mediaElem.innerWidth;
		adsRequest.linearAdSlotHeight = mediaElem.innerHeight;
		adsRequest.nonLinearAdSlotWidth = mediaElem.clientWidth;
		adsRequest.nonLinearAdSlotHeight = mediaElem.clientHeight / 3;
		adsRequest.vastLoadTimeout = adBreak.vastload;
		adsRequest.setContinuousPlayback = true;

		this.adsLoader.requestAds(adsRequest);
	}

	onContentPauseRequested = () => {
		this.adsCallbacks.onAdBreakStarts();
	}

	onStarted = () => {
		this.adsCallbacks.onAdStarted();

		this.timerUI.innerText = Math.floor(this.adsManager.getRemainingTime());
		this.timerUI.style.display = "block";

		this.countdownTimer = setInterval(() => {
			const time = this.adsManager.getRemainingTime()
			this.timerUI.innerText = Math.floor(time < 0 ? 0 : time);
		}, 1000);
	}

	onContentResumeRequested = () => {
		this.adsCallbacks.onAdBreakFinished(this.currentAdBreak);
	}

	onAdEnded = () => {
		this.stopTimer();
		this.player.displayLoader();
		this.adsCallbacks.onAdComplete(this.currentAdBreak);
	}	

	stopTimer = () => {
		this.timerUI.style.display = "none";
		clearInterval(this.countdownTimer);
	}

	onAdError(err) {
		const error = err.getError();
		console.log(error);

		this.stopTimer();
		this.adsCallbacks.onAdError(this.currentAdBreak, error); //player ref event

		this.adsManager && this.adsManager.destroy();
	}
}