export default class Analytics {
  constructor(analytics) {
    this.analytics = analytics;
  }

  /**
   * Send video event
   * @param {string} eventType 
   * @param {object} eventData
   * @async
   */
  async sendEvent(eventType, eventData) {
    if (!this.analytics) return;

    this.analytics.sendEvent(eventType, eventData, "video");
  }

  /**
   * Send ad event
   * @param {string} eventType 
   * @param {object} eventData
   * @async
   */
  async sendAdEvent(eventType, adEvent) {
    if (!this.analytics) return;
    let eventInfo = {};

    if (adEvent) {
      const adData = adEvent.getAd();

      if (adData) {
        const podInfo = adData.getAdPodInfo();

        eventInfo = {
          adId: adData.getAdId(),
          adSystem: adData.getAdSystem(),
          advertiserName: adData.getAdvertiserName(),
          creativeId: adData.getCreativeId(),
          title: adData.getTitle(),
          mediaUrl: adData.getMediaUrl(),
          adPodInfo: {
            totalAds: podInfo.getTotalAds(),
            maxDuration: podInfo.getMaxDuration()
          }
        }
      }
    }

    this.analytics.sendEvent(eventType, eventInfo, "video");
  }

  /**
   * Send video error event
   * @param {string} eventType 
   * @param {object} eventData
   * @async
   */
  async sendError(eventType, errorData) {
    if (!this.analytics) return;

    this.analytics.sendError(eventType, errorData, "video");
  }

  setVideoSession(video) {
    if (!this.analytics) return;

    this.analytics.setVideoSession(video);
  }
}