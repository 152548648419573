import { GET } from "Services/requests/get";
import Parser from "utils/parser";
/**
 * Module to send beconds. generate player beacons and sending them.
 * 
 * @class
 */
class Beacons {
  constructor() {
    /**
     * old beacon
     */
    this.beaconUrl = "";

    /**
     * The session beacon which only send once per session
     */
    this.sessionBeacon = "";

    /**
     * Beacon which will send for every event (new version for beaconUrl)
     */
    this.videoBeacon = "";

    /**
     * Copy of videoBeacon but with video macros changed
     */
    this.inVideoSession = "";

    /**
     * Copy of beaconUrl but with video macros changed
     */
    this.oldVideoSession = "";
  }

  init(urls) {
    this.beaconUrl = urls.beaconUrl;
    this.sessionBeacon = urls.beaconSession;
    this.videoBeacon = urls.beaconVideo;

    // start session
    this.sendEvent({ "[EVENT_ID]": 24 }, "session");
  }

  /**
   * Generating video beacon whlie changing video related macros.
   * 
   * @param {object} video The video object
   */
  generateVideoSession(video) {
    const macros = {
      "[CONTENT_SESSION_ID]": Date.now() + Math.floor(100000 + Math.random() * 900000),
      "[VIDEO_CONTENT_ID]": video.id,
      "[VIDEO_TITLE]": video.title,
      "[PLAYLIST_ID]": video.carouselId,
      "[CATEGORY_ID]": undefined
    };

    this.inVideoSession = Parser.parse(this.videoBeacon, macros, false);
    this.oldVideoSession = Parser.parse(this.beaconUrl, macros, false);
  }

  /**
   * Decide what beacons to fire.
   * 
   * @param {string} type Type of list of urls to fire.
   * 
   * @returns {array} List of beacons urls to fire.
   */
  urlsToFire(type) {
    switch (type) {
      case "session": return [this.sessionBeacon];
      case "video": return [this.inVideoSession, this.oldVideoSession];
      default: return [this.beaconUrl, this.videoBeacon];
    }
  }

  /**
   * Sends beacon event.
   * 
   * @param {object} macros Additonal macros related to the event
   * @param {string} type which urls to fire (depends where we are sending the beacon)
   * 
   * @async
  */
  async sendEvent(macros = {}, type) {
    const urls = this.urlsToFire(type);

    for (const beaconUrl of urls) {
      const _beacon = Parser.parse(beaconUrl, macros); // get temp macros and remove all unused macros
      this.fireBeacon(_beacon);
    };
  }

  /**
   * Sends error beacon.
   * 
   * @param {object} moreMacros Additonal macros related to the failed event
   * @param {string} type which urls to fire (depends where we are sending the beacon)
   * 
   * @async
  */
  async sendError(moreMacros = {}, type) {
    const urls = this.urlsToFire(type);

    for (const beaconUrl of urls) {
      const _beacon = Parser.parse(beaconUrl, moreMacros); // get temp macros and remove all unused macros
      this.fireBeacon(_beacon);
    };
  }

  /**
   * Fire the beacon url.
   * 
   * @param {string} url URL to fire
   * 
   * @async
   */
  async fireBeacon(url) {
    if (!url) return;

    if (!navigator.sendBeacon || url.includes("trk.playitviral.com/video/stats/ping.php")) {
      return GET(url);
    }

    navigator.sendBeacon(url);
  }
}

export default new Beacons();