import React from 'react';
import './topCarouselstory.css';

function CarouItemStory(props) {

	return (
		<>
			<div className="cat-item">
				<img className="cat-item-img" src={props.item.image} onError={props.onImageError} alt={props.item.title} />
			</div>
			<h2 className="cat-title">
				{props.item.title}
			</h2>
		</>
	)
}

export default React.memo(CarouItemStory)