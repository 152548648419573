import React,{useRef} from 'react'
import { shallowEqual, useSelector } from 'react-redux';
import appSettings from 'data/appSettings.json'
import Loader from '../Widgets/loaders/Loader'
import './splashScreen.css'

export default function SplashScreen(props) {
	const imgRef = useRef();

	const {splashScreenUrl} = useSelector(({app}) => {
		return {
			splashScreenUrl: app.splashScreenUrl
		}
	}, shallowEqual);
	
	const onLoaded = () => {
		imgRef.current.style.opacity = "1"; // just for transition
		props.onSplashScreenLoaded();
	}

	return (
		<div id="splash_screen" className="centerlize fullScreen">

			{splashScreenUrl
				? <img ref={imgRef} src={splashScreenUrl} onLoad={onLoaded} onError={onLoaded} alt="splash screen" />
				: !props.onAppError && <Loader />
			}

			<div className="build-version">
				<span>V{appSettings.version}</span>
			</div>
		</div>
	)
}
