import { store } from "./store"

/**
* Check if video is sensetive.
*
* @param {string} videoId Video ID.
*
* @returns true if it's sentsetive otherwise returns false.
*/
export const checkVideoIsSensitive = (videoId) => {
  const videosObjList = store.getState().entities.videos;
  const video = videosObjList[videoId];

  if (video) {
    if (video.parental_control) {
      return video.parental_control[0].title >= 18
    }
  }

  return false;
}

/**
* Get video object
*
* @param {string} videoId Video ID
* @param {string} caroueslId Carousel ID
*
* @returns New video object with the carousel title
*/
export const getVideo = ({ videoId, caroueslId }) => {
  const entities = store.getState().entities;
  const carouesl = entities.carousels[caroueslId]

  return {
    ...entities.videos[videoId],
    carouselTitle: carouesl.title
  }
}

/**
* Get carouesl Id by sending video ID.
*
* @param {string} videoId Video id
*
* @returns The first carousel ID in which the video ID exists
*/
export const findVideoInCarousel = (videoId) => {
  const carousels = store.getState().entities.carousels;

  for (const carouselId in carousels) {
    const _carousel = carousels[carouselId];
    const videoIndex = _carousel.videos.findIndex(_videoId => _videoId === videoId);

    if (videoIndex !== -1) {
      return carouselId
    }
  }

  return null;
}