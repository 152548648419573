import "@babel/polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AppManager from "./components/AppManager/AppManager";
import './App.css';
import { store } from "Services/redux/store";
import errorReport from "Services/analytics/errorReport";

window.addEventListener("error", (err) => {
	console.log(err);
	
	errorReport.trackError({
		message: err.message,
		stack: err.error.stack
	}, true);
});

ReactDOM.render(
	<Provider store={store}>
		<AppManager />
	</Provider>,
	document.getElementById('root'));