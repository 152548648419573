import { useEffect, useRef } from 'react';
import { keyCodes } from '../data/keyCodes';
import { addKeyHandler, removeKeyHandler } from 'utils/utiliesFunctions';
import { useSelector, shallowEqual } from "react-redux";

function useKeyHandler(keyHandlerSettings) {
	const { keys, isActive, dependency = [], debounce = 0, capture = false } = keyHandlerSettings;

	const { rtl } = useSelector(({ app }) => ({
		rtl: app.graphic.rtl
	}), shallowEqual);

	const debonceToggle = useRef(false);
	const timeout = useRef(null);

	useEffect(() => {

		const keyHandler = (event) => {
			if (!keys) return;

			let funcRef;

			// only on platform we have animations we adding debounce
			if (window.settings.platformSettings.animation && debounce) {
				if (debonceToggle.current) return;

				debonceToggle.current = true;

				clearTimeout(timeout.current);
				timeout.current = setTimeout(() => {
					debonceToggle.current = false;
				}, debounce);
			}

			switch (event.keyCode) {
				case keyCodes.RIGHT: funcRef = (rtl ? keys.left : keys.right); break;
				case keyCodes.LEFT: funcRef = (rtl ? keys.right : keys.left); break;
				case keyCodes.UP: funcRef = keys.up; break;
				case keyCodes.DOWN: funcRef = keys.down; break;
				case keyCodes.ENTER: funcRef = keys.enter; break;
				case keyCodes.BACK: event.preventDefault(); funcRef = keys.back; break;
				case keyCodes.BLUE: funcRef = keys.blue; break;
				case keyCodes.REWIND: funcRef = keys.rewind; break;
				case keyCodes.FORWARD: funcRef = keys.forward; break;
				case keyCodes.STOP: funcRef = keys.stop; break;
				case keyCodes.PLAY: funcRef = keys.play; break;
				case keyCodes.PAUSE: funcRef = keys.pause; break;
				case keyCodes.TOGGLE_PLAY: funcRef = keys.togglePlay; break;
				default:
					if (keys.default)
						funcRef = keys.default;
					break;
			}

			funcRef && funcRef(event);
		}

		if (isActive)
			addKeyHandler(keyHandler, capture);
		else {
			removeKeyHandler(keyHandler, capture);
		}

		return () => {
			removeKeyHandler(keyHandler, capture);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isActive, ...dependency]);
}

export default useKeyHandler;