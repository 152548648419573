import React from 'react'
import SubOptions from '../actions/SubOptions'

export default function ActionOptions(props) {
  return (
    <>
      <p className="item_title">{props.option.label}</p>

      <div className="action_item_bg centerlize">
        <props.option.Icon />
      </div>

      <SubOptions
        action={props.option.action}
        className={props.option.className}
        options={props.option.options}
        isActive={props.isActive}
        parentEnter={props.onItemSelected}
      />
    </>
  )
}
