import { Throttle } from "utils/utiliesFunctions";

/**
 * Handle ads request, will hold reference to ads library like: IMA. <br>
 * Responsible for sending analytics events, and handle ads errors.
 * 
 * @class
 */
export default class Ads {
  constructor(ads, playerRef) {
    /**
     * Castify player Instance
     */
    this._playerRef = playerRef;
    /**
     * Check if ad library loaded, and the ad manager loaded successfully
     */
    this.isActivate = ads !== undefined;

    // if there is no ads object there is no need to continue
    if (this.isActivate === false) return;

    /**
     * Castify player analytics module
     */
    this.Analytics = playerRef.Analytics;

    /**
     * Ad breaks rules
     */
    this.AdBreaks = new AdsSettings(ads);

    /**
     * Ad manager responsible to request ads from ad server
     */
    this.adManager = null;

    /**
     * Time between midrolls
     */
    this.adPod = ads.adPod;

    /**
     * Time in miliseconds to request ads again (in failed routine)
     * @type {number} 
     */
    this.failedInterval = ads.adInterval;

    /**
     * Ads vast
     */
    this.vastURL = ads.vastURL;

    /**
     * Request ads in failed routine
     */
    this.adFailedTimeout = null;

    /**
     * Midroll counter
     * @type {number} 
     */
    this.midCounter = -1;

    /**
     * Midroll counter function. runs every 1 second
     */
    this.adsCounterFunc = Throttle(this.adInterval, 1000);

    /**
     * Ads callbacks
     */
    this.callbacks = {
      /**
       * When starting ad break.
       */
      onAdBreakStarts: () => {
        this._playerRef.onAdBreakStarted();
        this.Analytics.sendEvent("adBreakStarted");
      },
      /**
       * When single ad starts.
       * 
       * @param adEvent Ad event data.
       */
      onAdStarted: (adEvent) => {
        this.Analytics.sendAdEvent("adStarted", adEvent);
      },
      /**
       * When single ad completes.
       */
      onAdComplete: () => {
        this.Analytics.sendEvent("adComplete");
      },
      /**
       * When ad break completed.
       * 
       * @param adbrak pre,mid,post.
       */
      onAdBreakFinished: (adBreak) => {
        // run ad break callbacks 
        this.onAdFinished(adBreak);

        this._playerRef.onAdBreakFinished();

        this.Analytics.sendEvent("adBreakCompleted");
      },
      /**
       * When ad failed.
       * 
       * @param adbrak pre,mid,post.
       * @param error Error data.
       */
      onAdError: (adBreak, error) => {
        this._playerRef.runParentCallBack("adError");

        // run adbreak callbacks
        this.onAdError(adBreak);

        // analytics
        this.Analytics.sendError("adError", {
          errorCode: error.getErrorCode(),
          type: error.getType(),
          message: error.getMessage()
        });
      },
      /**
       * on ad Impression.
       * 
       * @param adEvent data about the ad.
       */
      onImpression: (adEvent) => {
        this.Analytics.sendAdEvent("impression", adEvent);
      },
      /**
       * When ad reached the first quartile.
       * 
       * @param adEvent data about the ad.
       */
      onFirstQuartile: (adEvent) => {
        this.Analytics.sendAdEvent("firstQuartile", adEvent);
      },
      /**
       * When ad reached the third quartile.
       * 
       * @param adEvent data about the ad.
       */
      onThirdQuartile: (adEvent) => {
        this.Analytics.sendAdEvent("thirdQuartile", adEvent);
      }
    }
  }

  /**
   * Clean all timeouts and events this will be called when the player is about to unmount
   */
  cleanup() {
    clearTimeout(this.adFailedTimeout);
    this._playerRef.removeEvents([["timeupdate", this.adsCounterFunc]]);

    if (this.adManager)
      this.adManager.cleanup();
  }

  /**
   * Run callback after an ad break finished to play
   * 
   * @param {AdBreak} adBreak The ad break object
   */
  onAdFinished = (adBreak) => {
    switch (adBreak.type) {
      // if midRoll or midRollFailed *finished* to play set the ads counter again 
      case "preRoll":
        if (this.AdBreaks.adBreaks.midRoll)
          this._playerRef.addEvents([["timeupdate", this.adsCounterFunc]]);
        break;
      case "midRoll": case "midFailed":
        this._playerRef.addEvents([["timeupdate", this.adsCounterFunc]]);
        break;
      default: break;
    }
  }

  /**
   * Run callback after ad error.
   * 
   * @param {AdBreak} adBreak The ad break object.
   */
  onAdError = (adBreak) => {
    switch (adBreak.type) {
      case "preRoll":
        if (this.AdBreaks.adBreaks.midRoll)
          this._playerRef.addEvents([["timeupdate", this.adsCounterFunc]]);
        break;
      case "midRoll": case "midFailed": this.startMidFailedRoutine(); break;
      default: break;
    }
  }

  /**
   * Bind ad service library like: IMA
   * 
   * @param {object} adManagerClass adManager reference to request handle, and display ads
   */
  setAdManager(AdManagerClass) {
    if (!this.isActivate) return;

    try {
      this.adManager = new AdManagerClass({
        player: this._playerRef,
        vast: this.vastURL,
        callbacks: this.callbacks
      });
    }
    catch (error) {
      this.isActivate = false;
      console.log({ text: "Playing media without Ads, check ad blocker", error })
    }
  }

  /**
   * Request ad from ad manager
   * 
   * @param {string} adTiming Adbreak timeing 
   */
  requestAd(adTiming) {
    if (!this.isActivate) return;

    const adBreak = this.AdBreaks.adBreaks[adTiming];

    if (!adBreak) return;

    if (adTiming !== "midFailed")
      this.Analytics.sendEvent("adOpportunity");

    this.Analytics.sendEvent("adRequest");
    this.adManager.requestAd(adBreak);
  }

  /**
   * Run every 1 second and check if the counter is equal to ad pod if so, request ad
   * 
   * @param {string} adTiming Adbreak timeing 
   */
  adInterval = () => {
    this.midCounter++;

    if (this.midCounter === this.adPod) {
      this._playerRef.removeEvents([["timeupdate", this.adsCounterFunc]]);
      this.midCounter = 0;
      this.requestAd("midRoll");
    }
  }

  /**
   * when midRoll failed to display, request every 'failedInterval' seconds.
   */
  startMidFailedRoutine() {
    clearTimeout(this.adFailedTimeout);
    this.adFailedTimeout = setTimeout(() => this.requestAd("midFailed"), this.failedInterval);
  }

  /**
   * Reset all values, timers and timeout.
   */
  resetValues() {
    clearTimeout(this.adFailedTimeout);
    this.midCounter = 0;
    this.adFailedTimeout = null;
  }
}

class AdsSettings {
  constructor(ads) {
    this.adBreaks = {
      preRoll: ads.preRoll ? new AdBreak("preRoll", 2500) : null,
      midRoll: ads.midRoll ? new AdBreak("midRoll", 5000) : null,
      midFailed: ads.midRoll ? new AdBreak("midFailed", 7000) : null,
      postRoll: ads.postRoll ? new AdBreak("postRoll", 3000) : null
    };
  }
}

/**
 * Create ad break object, and add settings about this break <br>
 * like: setting the vast load timeout.
 * 
 * @see [adsRenderingSettings]{@link https://developers.google.com/interactive-media-ads/docs/sdks/html5/client-side/reference/js/google.ima.AdsRenderingSettings}
 * 
 * @class
 */
class AdBreak {
  constructor(type, vastload) {
    this.type = type;
    this.vastload = vastload;
  }
}