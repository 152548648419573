import React from 'react';
import ContentData from './ContentData';
import ImageTransition from 'components/Widgets/images/ImageTransition';
import { useSelector, shallowEqual } from "react-redux";
import { buildMetaData } from './metaData';

function DetailedView(props) {
	const { defaultThumbnail } = useSelector(({ app }) => ({
		defaultThumbnail: app.graphic.defaultThumbnail
	}), shallowEqual);

	const metaContent = buildMetaData(props.selectedVideo);

	return (
		<div className="detailed_view_container">
			<ImageTransition selectedVideo={props.selectedVideo} defaultThumbnail={defaultThumbnail} />

			<header className="app_info">
				<h1 className="home_title">{props.selectedVideo.title}</h1>
				<ContentData contentData={metaContent} />
				<p className="home_desc cutLines">{props.selectedVideo.description}</p>
			</header>
		</div>
	)
}

export default DetailedView;
