export default class GoogleAnalytics {
  constructor(tag) {
    this.name = "googleAnalytics";
    this.type = "analytics";
    this.tag = tag;
  };

  /**
   * Load google analytics scripts
   */
  load() {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      const secondScript = document.createElement("script");
  
      script.src = `https://www.googletagmanager.com/gtag/js?id=${this.tag}`;
      script.type = "text/javascript";
      script.async = true;
      script.onload = () => resolve(this);
      script.onerror = () => reject(this);
      secondScript.text = `window.dataLayer = window.dataLayer || [];function gtag(){window.dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${this.tag}');`;
      
      document.head.appendChild(script);
      document.head.appendChild(secondScript);
    })

  };

  /**
   * Send google analytics.
   * 
   * @param {object} data Google analaytics params
   * @param {string} data.eventName The event name
   * @param {object} data.parameters The event parameter
   */
  sendEvent(data) {
    const { eventName, parameters } = data;

    if (!parameters.event_category) {
      return console.error("missing event category");
    }
    window.gtag('event', eventName, parameters);
  }

  /**
   * Send google analytics exceptions.
   * 
   * @param {string} description The event description
   * @param {boolean} fatal Is error Fatal
   */  
  sendError(description, fatal){
    window.gtag('event', "exception", {
      description,
      fatal   // set to true if the error is fatal
    });
  }
};