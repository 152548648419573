/**
 * Get event realted data.
 * 
 * @param {string} event Event name.
 * @param {object} data Additonal data to add.
 * 
 * @see [Docs]{@link https://docs.google.com/document/d/18QX0x0ftTpOHp-A-gxf2-NdfcMfTqYJ88ICT_ff_TfQ}
 */
export default (event, data) => {
  const macrosData = {
    play: () => ({
      googleAnalytics: new GoogleAnalyticsVideo("Play"),
      castify: new CastifyEvent(21)
    }),
    pause: () => ({
      googleAnalytics: new GoogleAnalyticsVideo("Pause"),
      castify: new CastifyEvent(22)
    }),
    openApp: (deepLinkData) => ({
      googleAnalytics: new GoogleAnalyticsApp("Open app", true),
      castify: new CastifyEvent(24, deepLinkData)
    }),
    exitApp: () => ({
      googleAnalytics: new GoogleAnalyticsApp("Exit app", true),
      castify: new CastifyEvent(25)
    }),
    adRequest: () => ({
      googleAnalytics: new GoogleAnalyticsAds("Ad request"),
      castify: new CastifyEvent(26)
    }),
    impression: () => ({
      googleAnalytics: new GoogleAnalyticsAds("Ad impression"),
      castify: new CastifyEvent(27)
    }),
    adComplete: () => ({
      googleAnalytics: new GoogleAnalyticsAds("Ad complete"),
      castify: new CastifyEvent(28)
    }),
    startVideo: () => ({
      googleAnalytics: new GoogleAnalyticsVideo("Start video"),
      castify: new CastifyEvent(29)
    }),
    adOpportunity: () => ({
      googleAnalytics: new GoogleAnalyticsAds("Ad Opportunity"),
      castify: new CastifyEvent(31)
    }),
    endVideo: () => ({
      googleAnalytics: new GoogleAnalyticsVideo("End Video", true),
      castify: new CastifyEvent(32)
    }),
    adStarted: (adInfo) => ({
      googleAnalytics: new GoogleAnalyticsAds("Ad started"),
      castify: new CastifyEvent(34, adInfo)
    }),
    firstQuartile: (adInfo) => ({
      googleAnalytics: new GoogleAnalyticsAds("Ad first quartile", true),
      castify: new CastifyEvent(35, adInfo)
    }),
    thirdQuartile: (adInfo) => ({
      googleAnalytics: new GoogleAnalyticsAds("Ad third quartile", true),
      castify: new CastifyEvent(37, adInfo)
    }),
    pageVisit: (pageData) => ({
      googleAnalytics: new GoogleAnalyticsApp("Page visit"),
      castify: new CastifyEvent(40, pageData)
    }),
    adBreakStarted: () => ({
      googleAnalytics: new GoogleAnalyticsAds("Ad break started", true),
      castify: new CastifyEvent(43)
    }),
    adBreakCompleted: () => ({
      googleAnalytics: new GoogleAnalyticsAds("Ad break finished", true),
      castify: new CastifyEvent(44)
    }),
    adError: (err) => ({
      googleAnalytics: new GoogleAnalyticsAds("Ad Error"),
      castify: new CastifyError(err)
    }),
    videoError: (err) => ({
      googleAnalytics: new GoogleAnalyticsError(`Video: ${err.videoId} failed, url: ${err.url}.`, false),
      castify: new CastifyError(err)
    })
  }
  const eventData = macrosData[event];

  return eventData ? eventData(data) : undefined;
}

/**
 * Google analytics genertor
 * 
 * @class
 */
class GoogleAnalytics {
  constructor(eventName, nonInteraction = false) {
    this.eventName = eventName;
    this.non_interaction = nonInteraction;
  }
}

/**
 * Google analytics app object generator.
 * 
 * @class
 * @augments GoogleAnalytics
 */
class GoogleAnalyticsApp extends GoogleAnalytics {
  constructor(eventName) {
    super(eventName, true);
    this.parameters = {
      event_category: "App"
    }
  }
}

/**
 * Google analytics ads object generator.
 * 
 * @class
 * @augments GoogleAnalytics
 */
class GoogleAnalyticsAds extends GoogleAnalytics {
  constructor(eventName) {
    super(eventName);
    this.parameters = {
      event_category: "Ads"
    }
  }
}

/**
 * Google analytics video object generator.
 * 
 * @class
 * @augments GoogleAnalytics
 */
class GoogleAnalyticsVideo extends GoogleAnalytics {
  constructor(eventName) {
    super(eventName);
    this.parameters = {
      event_category: "Video"
    }
  }
}

/**
 * Google analytics error object generator.
 * 
 * @class
 * @augments GoogleAnalytics
 */
class GoogleAnalyticsError {
  constructor(description, fatal) {
    this.description = description;
    this.fatal = fatal;
  }
}

/**
 * Castify analytics generator.
 * 
 * @class
 */
class CastifyEvent {
  constructor(eventID, eventValue) {
    this["[EVENT_ID]"] = eventID;

    if (eventValue) {
      this["[EVENT_VALUE]"] = JSON.stringify(eventValue);
    }
  }
}

/**
 * Castify analytics error generator.
 * 
 * @class
 * @augments CastifyEvent
 */
class CastifyError extends CastifyEvent {
  constructor(error) {
    super(30);
    this["[ERROR_ID]"] = JSON.stringify(error);
  }
}