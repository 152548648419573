import ChannelSettings from "utils/channelSettings/ChannelSettings";
import CastifyPlayer from "Services/Player/castifyPlayer/castifyPlayer";
import EventsBus from "utils/eventsBus";
// import BitMovin from "./BitMovin";
// import NexPlayer from "./NexPlayer";

/**
 * Centerlized module to handle multiple players.
 * 
 * @class
 */
class PlayerInterface {
  constructor() {
    /**
     * The player instance
     */
    this.playerInstance = null;

    /**
     * Register and listen to events outside the player module.
     * Connect UI to player events after an player occasion like: player changed content type (audio|video)
     */
    this.EventBus = new EventsBus();
  }

  /**
   * Get the player instance.
   * 
   * @param {object} data Player events, analytics library to use and more. 
   */
  init(data) {
    this.playerInstance = this.getInstance(data);

    // this.playerInstance.initPlayer(data);
  }

  action(action, ...data) {
    const actionRef = this.playerInstance[action];

    if (actionRef) return actionRef(...data);
    else console.log("unsupported action in this player type");
  }

  /**
   * Add events to player
   * 
   * @param {Array} events List of tuples
   */
  addEvents(events) {
    this.playerInstance.addEvents(events);
  }

  /**
   * Remove events from player.
   * 
   * @param {Array} events List of tuples
   */
  removeEvents(events) {
    this.playerInstance.removeEvents(events)
  }

  /**
   * Get the class instance based on channel setting player property.<br>
   * Default is CastifyPlayer.
   * 
   * @param {object} data Player events and settings.
   * @returns Plyaer class.
   */
  getInstance(data) {
    switch (ChannelSettings.getSettings("player").type) {
      // case "nexPlayer": return new NexPlayer();
      // case "bitMovin": return new BitMovin();
      case "castify":
      default: return new CastifyPlayer(data, this);
    }
  }

  /**
   * A nice function to run player actions like: play, pause stop.
   * 
   * @param {string} action The action to run. 
   * @returns {undefined | string} On "togglePlay" will return the new state on the player (paused or playing).
   */
  controller(action) {
    switch (action) {
      case "togglePlay": return this.playerInstance.togglePlay();
      case "play": this.playerInstance.play(); break;
      case "pause": this.playerInstance.pause(); break;
      case "replay": this.playerInstance.replay(); break;
      case "stop": this.playerInstance.stop(); break;
      default: break;
    }
  }
}

export default new PlayerInterface()