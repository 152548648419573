import React from 'react'
import SkipNextIcon from "@material-ui/icons/SkipNext";
import useLanguage from 'hooks/useLanguage';
import { useSelector, shallowEqual } from "react-redux"
import "./nextVideo.css";
import Image from 'components/Widgets/images/Image';

function NextVideo(props) {
	const getWord = useLanguage();

	const { defaultThumbnail } = useSelector(({ app, video }) => {
		return {
			defaultThumbnail: app.graphic.defaultThumbnail
		}
	}, shallowEqual);

	return (
		<div className="controls_item" data-action="playNext" tabIndex={-1} aria-label={`Play next video, ${props.nextVideo.title}`}>
			<div className="nextVideoBtn list"
				role="button"
				onClick={props.onClick}

				onMouseOver={props.mouseOver}
			>
				<div className="next_info" id="next_info">
					<h3 className="next_label">{getWord("playNext")}</h3>
					<p className="next_title cutLines">{props.nextVideo.title}</p>
				</div>

				<div className="next_img">
					<Image
						layout="cover"
						src={props.nextVideo.thumbnail}
						defaultImage={defaultThumbnail}
						width="180"
						height="105"
					/>
					<div className="next_overlay centerlize">
						<SkipNextIcon />
					</div>
				</div>

			</div>
		</div>
	)
}

export default React.memo(NextVideo);