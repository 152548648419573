import Hls from "hls.js";

export default class HlsPlayer {
	static HLS_CONFIG = {
		autoStartLoad: false,
		maxBufferSize: 15 * 1000 * 1000,
		maxBufferLength: 15,
		backBufferLength: 15,
		maxMaxBufferLength: 200,
		initialLiveManifestSize: 2
	}

	constructor(playerRef) {
		this.playerRef = playerRef; // castify player instance
		this.timeBeforeAds = 0;
		this.errorRaisedBefore = false;
	}

	initHlsObject() {
		this.destroyHLS();

		this.hls = new Hls(HlsPlayer.HLS_CONFIG); // hls instance

		this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
			this.hls.loadSource(this.playerRef.getVideoSource());
			
			this.hls.on(Hls.Events.MANIFEST_PARSED, (...data) => {
				this.hls.startLoad(this.timeBeforeAds);
			});
		});

		this.hls.on(Hls.Events.ERROR, (event, data) => {
			const getLastPos = this.getPlayerPos();

			console.log(data)
			if (data.fatal) {

				switch (data.type) {
					case Hls.ErrorTypes.NETWORK_ERROR: this.hls.startLoad(getLastPos); break;
					case Hls.ErrorTypes.MEDIA_ERROR: this.hls.recoverMediaError(); break;
					default: this.destroyHLS(); break;
				}
			}
			// if (this.errorRaisedBefore) {
			// 	this.playerRef.onError(data.type);
			// 	this.errorRaisedBefore = false;
			// 	return
			// }

			// this.errorRaisedBefore = true;
		});
	}

	cleanup() {
		this.destroyHLS();
	}

	playVideo() {
		if (Hls.isSupported()) {
			this.initHlsObject()
			this.hls.attachMedia(this.playerRef.getMediaRef());
		}
	}

	destroyHLS() {
		if (this.hls)
			this.hls.destroy();
	}

	getPlayerPos() {
		return this.playerRef.getCurrentTime() || 0;
	}

	onAdBreakStarted() {
		this.timeBeforeAds = this.getPlayerPos();
		this.hls.detachMedia();
	}

	onAdBreakFinished() {
		this.hls.attachMedia(this.playerRef.getMediaRef());
	}
}
