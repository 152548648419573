import { GET } from "Services/requests/get";
// TODO support more than one captions


/**
 * Responsible for creating, switching and removing tracks.
 * 
 * @class
 */
export default class Tracks {
  constructor(playerRef, subtitleType, displayTracks) {

    /**
     * Reference for castify player.
     */
    this.playerRef = playerRef;

    /**
     * List of all the downloaded tracks for the current video.
     */
    this.stackTracks = {};

    /**
     * The subtitle type.
     */
    this.subtitleType = subtitleType; // subtitle type - blob / normal

    /**
     * Set the active track so when we toggle the visibilty of the captions we display this by default.
     */
    this.defaultTrackLang = "";

    /**
     * Whether or not display the tracks - mostly controlled by platform settings.
     */
    this.displayTracks = displayTracks;
  }

  /**
   * Set if we should display the tracks or not.
   * 
   * @param {boolean} shouldDisplayTracks 
   */
  setDispalyTracks(shouldDisplayTracks) {
    this.displayTracks = shouldDisplayTracks;
  }

  /**
   * Get the media element text tracks.
   * 
   * @returns Text tracks.
   */
  getTextTracks() {
    return this.playerRef.getMediaRef().textTracks;
  }

  /**
   * Fires when video has changed so we can reset all tracks and add new ones.
   * Build the new video track stacks and build the first track (if exist).
   * 
   * @async
   * @param {object} captions The caption object associated with the video object
   */
  async onVideoChanged(captions) {
    if (!captions) return;

    this.removeAllTracks();
    this.stackTracks = this.setStackTracks(captions);
    const newTrack = await this.generateTrack(captions[0], true);
    this.appendTrack(newTrack);
    setTimeout(() => {
      this.toggleTracks(captions[0].srclang);
    }, 100);
  }

  /**
   * Build object with all the tracks the media has.
   * 
   * @param {array} subtitles Array of all the captions associated with the media object.
   * @returns New stack track object with all the possible tracks for the current media.
   */
  setStackTracks(subtitles) {
    this.defaultTrackLang = subtitles[0].srclang; // the the default to the first subtitle we got from json

    return subtitles.reduce((total, current) => {
      const lang = current.srclang;
      return { ...total, [lang]: current };
    }, {});
  }

  /**
   * Generte new track.
   * 
   * @async
   * @param {object} captionObject The caption object
   * @param {boolean} isDefault Should set the track as default track
   * 
   * @returns {Promise} new track object
   */
  async generateTrack(captionObject, isDefault = false) {
    if (!captionObject) return;

    const track = document.createElement('track');
    let trackURL = captionObject.src;

    // remove this track from stackTracks
    delete this.stackTracks[captionObject.srclang];

    if (this.subtitleType === "blob") {
      const blobVtt = await GET(captionObject.src, { type: "blob" });
      trackURL = URL.createObjectURL(blobVtt);
    }

    return Object.assign(track, { ...captionObject, src: trackURL, default: isDefault });
  }

  /**
   * Change the current playing text track.
   * 
   * @param {string} newTrackLang ISO language of the new text track
   */
  async changeTrack(newTrackLang) {
    // if newTrackLang is 'default' we will switch it back to 'this.defaultTrackLang' (assigned to the first caption object)  
    const trackLang = newTrackLang === "default" ? this.defaultTrackLang : newTrackLang;
    const newTrackList = this.stackTracks[trackLang];

    // if newTrackList is not falsy it means we need to download the track first
    if (newTrackList) {
      const newTrack = await this.generateTrack(newTrackList, false); // generate new track
      this.appendTrack(newTrack);
    }

    // 'off' or already exist track 
    this.toggleTracks(trackLang);
  }

  /**
  * Add track node to the DOM.
  * 
  * @param track Track element to bind to the DOM
  */
  appendTrack(track) {
    if (!track) return;
    const mediaRef = this.playerRef.getMediaRef()

    // track must be a track element
    mediaRef.appendChild(track);
  }

  /**
   * Toggle between active tracks.
   * 
   * @param {string} newTrackLang The ISO of the text track language want to display
   */
  toggleTracks(newTrackLang) {
    const tracks = this.playerRef.getMediaRef().textTracks;

    for (const track of tracks) {
      track.mode = track.language === newTrackLang ? "showing" : "disabled";
    }
  }

  /**
   * Remove all tracks from the media element.
   */
  removeAllTracks() {
    const mediaElement = this.playerRef.getMediaRef();

    [...mediaElement.children].forEach((child, index) => {
      if (child.nodeName === "TRACK")
        mediaElement.removeChild(mediaElement.children[index]);
    })
  }

  /**
   * Before ads starts remove all tracks
   */  
  adStarted() {
    this.toggleTracks("off");
    this.removeAllTracks();
  }

  /**
   * After ads Finished generate track
   * 
   * @param captions captions list
   */   
  async adFinished(captions) { 
    if(!captions) return;

    const newTrack = await this.generateTrack(captions[0], true);
    this.appendTrack(newTrack);
  }
}