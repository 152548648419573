import React, { useRef, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types';
import './gridStyles.css';
import useKeyHandler from 'hooks/useKeyHandler';
import WithKeyHandler from 'HOC/WithKeyHandler';
import { dataAttr, Throttle } from 'utils/utiliesFunctions';
import { KeyHandlerCode } from 'data/constants';
import useFunction from 'hooks/useFunction';
import { useDispatch } from "react-redux";
import { setKeyHandler } from 'Services/redux/app/actions';


function Grid(props) {
  const dispatch = useDispatch();

  const movingDiv = useRef();
  const staticDiv = useRef();
  const activeElem = useRef();
  const scrolling = useRef(0);

  useEffect(() => {
    // select the first item in the grid
    selectItem(movingDiv.current.firstChild);
    // set the transition duration or .3 as default
    movingDiv.current.style.transitionDuration = `${props.horizntalTiming || .3}s`;

    const wheel = ({ deltaY }) => {
      if (deltaY < 0) // scroll up
        up(true);
      else if (deltaY > 0) // scroll down
        down();
    }
    const wheelThrottle = Throttle(wheel, 200);

    const movingDivElement = movingDiv.current;

    movingDivElement.addEventListener("wheel", wheelThrottle);
    return () => {
      movingDivElement.removeEventListener("wheel", wheelThrottle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps	
  }, []);

  useEffect(() => {
    scrolling.current = 0;
    movingDiv.current.style.transform = `translate3d(0,${scrolling.current}px,0)`;
    selectItem(movingDiv.current.firstChild ,false);

    // eslint-disable-next-line react-hooks/exhaustive-deps	
  }, [props.content]);

  const selectItem = (elem, setFocus = true) => {
    activeElem.current && activeElem.current.classList.remove("active");
    activeElem.current = elem;

    if (activeElem.current) {
      activeElem.current.classList.add("active");
      setFocus && activeElem.current.focus();
    }
  }

  const down = () => {
    const _activeElement = activeElem.current; // fixed for reference
    let currentElement = activeElem.current; // the current element we check in the loop
    let trigger = true;

    const { top: active_top, height: active_height, left: active_left } = _activeElement.getBoundingClientRect();

    while (trigger) {
      // get the next sibling
      const newElementSibling = currentElement.nextElementSibling;

      // while scrolling down and in the while loop, if we there is no next sibling it means we got to the end so cancel the loop and break it,
      //  select the last element we found
      if (!newElementSibling) {
        trigger = false;

        if (active_top !== currentElement.getBoundingClientRect().top){
          selectItem(currentElement)
          scroll(-active_height, "down")
        }
        break;
      }

      // get the dimension of the current elemet we are checking
      const current_left = newElementSibling.getBoundingClientRect().left;

      if (active_left === current_left) {
        // stop the loop
        trigger = false;
        selectItem(newElementSibling)
        scroll(-active_height, "down")
      }

      // set the current elemt equal to his next sibling
      currentElement = newElementSibling;
    }
  };

  // there will always we an element to the top of the current one, if there isn't we are on the first row 
  const up = (fromWheel) => {
    const _activeElement = activeElem.current; // fixed for reference
    let currentElement = activeElem.current; // the current element we check in the loop
    let trigger = true;

    const { height: active_height, left: active_left } = _activeElement.getBoundingClientRect();

    while (trigger) {
      currentElement = currentElement.previousElementSibling;
      // if we don't find the element and there is no more element to check, break the loop and go up (if need)
      if (!currentElement) {
        if (fromWheel === true) break;

        props.reachedUP && props.reachedUP();

        trigger = false;
        break;
      }
      // get the dimension of the current elemet we are checking
      const current_left = currentElement.getBoundingClientRect().left;

      if (active_left === current_left) {
        selectItem(currentElement);
        scroll(active_height, "up", _activeElement);
        break;
      }
    }
  }

  const enter = () => {
    if (props.enter) {
      props.enter(+dataAttr(activeElem.current, "index"));
    }
  }


  const scroll = (amount, direction, elem) => {
    if (direction === "down" && movingDiv.current.getBoundingClientRect().bottom < window.innerHeight)
      return

    if (direction === "up") {
      if (staticDiv.current.getBoundingClientRect().top < elem.getBoundingClientRect().top - 10)
        return
    }

    const newScroll = scrolling.current + amount;
    scrolling.current = newScroll > 0 ? 0 : newScroll
    movingDiv.current.style.transform = `translate3d(0,${scrolling.current}px,0)`;
  }

  const mouseOver = ({ currentTarget }) => selectItem(currentTarget);
  const clickHandler = useFunction(enter);
  const mouseOverHandler = useFunction(mouseOver);

  const renderItems = () => {
    const getAriaLabel = item => item.title || item.item_title

    return props.content.map((item, index) => (
      <div key={item.id || index} className={props.class} tabIndex="0" aria-label={getAriaLabel(item)} data-index={index} onClick={clickHandler} onMouseOver={mouseOverHandler}>
        {props.renderItem(item)}
      </div>
    ))
  };

  const memoItems = useMemo(renderItems, [props.content])

  useKeyHandler({
    keys: {
      left: () => {
        const currentElement = activeElem.current;
        const prevElement = currentElement.previousElementSibling;

        // if there is no previous sibling or the previous sibling is not in the same row, go to menu 
        if (!prevElement || prevElement.getBoundingClientRect().top !== currentElement.getBoundingClientRect().top) {
          //when we at the left most item
          dispatch(setKeyHandler(KeyHandlerCode.MENU));
          return;
        }

        selectItem(prevElement);
      },
      right: () => {
        const currentElement = activeElem.current;
        const nextElement = currentElement.nextElementSibling;

        // if there is no sibling stop here
        if (!nextElement) return;

        // if the next sibling is not in the row stop here
        if (nextElement.getBoundingClientRect().top !== currentElement.getBoundingClientRect().top) return;

        selectItem(nextElement);
      },
      down, up, enter,
      back: () => {
        props.onBack ? props.onBack() : dispatch(setKeyHandler(KeyHandlerCode.MENU))
      }
    },
    isActive: props.isActive,
    debounce: 150
  });

  return (
    <div className={`${props.isActive ? "activeGrid" : ""} staticDiv`} ref={staticDiv}>
      <div className="movingDiv" ref={movingDiv}>
        {memoItems}
      </div>
    </div>
  )
}

Grid.propTypes = {
  content: PropTypes.array.isRequired, // list of videos to display 
  renderItem: PropTypes.func.isRequired, // template to render
}

export default WithKeyHandler(React.memo(Grid), KeyHandlerCode.GRID)