import React, { useState, useEffect, useRef, useCallback } from 'react'
import DetailedView from './DetailedView';
import CarouselManager from '../../components/carousel/CarouselManager';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { CarouselManagerTypes } from 'data/constants';
import { setKeyHandler } from 'Services/redux/app/actions';
import { KeyHandlerCode } from 'data/constants';

export default function DetailedPage(props) {
	const dispatch = useDispatch();

	const { backgroundImg, backgroundColor } = useSelector(({ app }) => ({
		backgroundImg: props.container.graphic.page.page_background_image || app.graphic.backgroundImg,
		backgroundColor: props.container.graphic.page.page_background_color || app.graphic.backgroundColor
	}), shallowEqual);

	const [activeVideo, setActiveVideo] = useState({});
	const bg = useRef();

	useEffect(() => {
		Object.assign(bg.current.style, {
			backgroundImage: `url('${backgroundImg}')`,
			backgroundColor: backgroundColor
		});

		dispatch(setKeyHandler(KeyHandlerCode.CAROUSEL));
	}, [backgroundImg, backgroundColor, dispatch]);

	// when we reached the top left of the carousel and prssing left again
	const onCarouselReachedLeft = useCallback(() => {
		dispatch(setKeyHandler(props.settings.horizntalEdge || KeyHandlerCode.MENU));
	}, [dispatch, props.settings.horizntalEdge]);

	const setActiveCarouselParent = useCallback(video => {
		setActiveVideo(video);
	}, []);

	return (
		<div className="detailed_background fullScreen" ref={bg}>
			<DetailedView selectedVideo={activeVideo} />

			<CarouselManager
				content={props.container.content}
				carouselManagerType={CarouselManagerTypes.HOME}
				type={props.type}
				carouselHeader="h2"
				reachedLeft={onCarouselReachedLeft}
				onItemSelected={setActiveCarouselParent}
			/>
		</div>
	)
}