import React, { useCallback } from 'react';
import PopupActionRow from '../PopupActionRow';
import { useDispatch } from "react-redux";
import { setVideoVisibility } from 'Services/redux/video/actions';
import "./parentalControl.css";

const buttons = [
  { label: "Back", action: "back" },
  { label: "Proceed", action: "procceed" }
];

export default function AgeConfirmation(props) {
  const dispatch = useDispatch();

  const enter = useCallback(action => {
    props.exitPopup();

    if (action === "procceed") {
      window.settings.appSettings.approved_sensitive_content = true;
      dispatch(setVideoVisibility(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="ageConfirmation_popup">
      <h2>DISCLAIMER:</h2>
      <p>
        This page may contain sexually oriented adult content.
        You must be at least 18 years old to access this content.
        If you are not yet 18, if adult material offends you,
        or if you are accessing this site from any country or locale where adult material is prohibited by law,
        you are not permitted to access this content for any reason. Please leave the page now.
        <span className="confirm_area">
          I confirm I'm over 18 years old, I've read and understood the disclaimers and terms of usage above.
        </span>
      </p>
      <PopupActionRow options={buttons} back={props.exitPopup} enter={enter} />
    </div>
  )
}
