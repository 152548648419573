import React, { useState, useRef, useEffect } from 'react'
import useKeyHandler from 'hooks/useKeyHandler';
import useLanguage from 'hooks/useLanguage';
import { useDispatch } from "react-redux";
import { setKeyHandler } from 'Services/redux/app/actions';
import { KeyHandlerCode } from 'data/constants';
import WithKeyHandler from 'HOC/WithKeyHandler';
import useFunction from 'hooks/useFunction';

let typingTimeout;

function Input(props) {
	const dispatch = useDispatch();

	const getWord = useLanguage();
	const [inputValue, setInputValue] = useState("");
	const [shouldDisplayTotalVideos, setShouldDisplayTotalVideos] = useState(false);
	const inputRef = useRef();

	useEffect(() => {
		return () => {
			clearTimeout(typingTimeout);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (props.isActive) {
			inputRef.current.focus();
		}
	}, [props.isActive]);

	useEffect(() => {
		setShouldDisplayTotalVideos(inputValue.length > 2);
	}, [inputValue]);

	const handleChange = (event) => {
		const inputValue = event.type === "change" ? event.currentTarget.value : event;
		clearTimeout(typingTimeout);
		// 	if (inputValue.length > inputRef.current.value) // delete with a remote control key
		// 		if (inputValue.length < 3) return
		setInputValue(inputValue);

		typingTimeout = setTimeout(() => {
			props.handleInputChange(inputValue);
		}, 500);
	}

	const back = () => {
		if (window.settings.platform === "zeasn") {
			if (!inputRef.current.value)
				return dispatch(setKeyHandler(KeyHandlerCode.GRID))

			// if there is value in input just delete it
			const newValue = inputRef.current.value.slice(0, inputRef.current.value.length - 1)
			handleChange(newValue);
		} else
			dispatch(setKeyHandler(KeyHandlerCode.GRID));
	}

	const handleClick = useFunction(() => dispatch(setKeyHandler(KeyHandlerCode.SEARCH_INPUT)));

	useKeyHandler({
		keys: {
			back,
			enter: () => inputRef.current.focus(),
			down: () => dispatch(setKeyHandler(KeyHandlerCode.GRID)),
			default: (event) => {
				if (window.settings.platform !== "vizio") return

				switch (event.keyCode) {
					case 8:
						const newValue = inputRef.current.value.slice(0, inputRef.current.value.length - 1);
						handleChange(newValue);
						break;
					default:
						if ((event.keyCode >= 44 && event.keyCode <= 92) || (event.keyCode >= 160 && event.keyCode <= 176) || (event.keyCode >= 219 && event.keyCode <= 222)) {
							inputRef.current += event.key;
						}
						break;
				}
			}
		},
		isActive: props.isActive
	})

	return (
		<div className="search_input_container" role="search">
			<input
				id="searchInput"
				type="text"
				autoComplete="off"
				className={props.isActive ? "active" : null}
				ref={inputRef}
				value={inputValue}
				onChange={handleChange}
				onClick={handleClick}
				style={{ border: `4px solid ${props.color}`, color: props.color }}
				placeholder={getWord("search_placeholder")}
			/>

			<div className={"resultsNum" + (shouldDisplayTotalVideos ? " visible" : "")}>
				{<p>{getWord("found")} <b>{props.length}</b> {getWord("videos")}</p>}
			</div>
		</div>
	)
}

export default WithKeyHandler(Input, KeyHandlerCode.SEARCH_INPUT)