import GoogleAnalytics from "Services/plugins/googleAnalytics";
import Youbora from "Services/plugins/youbora";
import DefaultValues from "./ChannelDefault.json";

/**
 * Channel settings is used to add plugins / scripts, to override default behaviors. <br>
 * It's also possible to retrieve those settings and plugins. <br>
 * See - [Doc]{@link https://docs.google.com/document/d/1l9xwG0PBh_K3-gHdHQIANH_MKIa-vRqFBwlzSSSoEKs/edit}
 * 
 * @class
 */
class ChannelSettings {
  constructor() {
    /**
     * general settings to override default values in app like: using another player
     */
    this.generals = DefaultValues.generals;

    /**
     * List of plugins.
     */
    this.plugins = [];
  }

  /**
   * Load plugins. The plugin must be supported and have it's own module.
   * There can be various of types like: analytics, player.
   * Make sure to give every type it's function.
   * We rather support in advance the plugin because TVs gives us hard time with uncompiled code and es6 and above.
   * 
   * @async
   * @static
   * 
   * @param {array} _plugins List of plugins to load.
   */
  static async loadPlugins(_plugins) {
    const pluginsList = {
      googleAnalytics: GoogleAnalytics,
      youbora: Youbora
    }

    try {
      const plugins = Object.entries(_plugins);

      const downloadedPlugins = await Promise.all(
        plugins.map(([name, key]) => {
          return new Promise((resolve, reject) => {
            //check if this plugin in pluginList
            const pluginRef = pluginsList[name];

            if (!pluginRef) return console.warn(`Unknown or unsupported plugin: ${name}`);

            // initialize the plugin with data
            const initPlugin = new pluginRef(key);

            try {
              initPlugin.load().then(resolve);
            } catch (err) {
              console.warn(`Plugin installing failed: ${name}`, err);
              reject(name);
            }
          });
        }));

      return downloadedPlugins.reduce((total, current) => {
        const type = current.type || "other";
        const info = total[type] || [];

        return { ...total, [type]: [...info, current] }
      }, {});
    } catch (err) {
      throw err
    }
  }

  /**
   * Load scripts. Script does not need to have it's own moudle, but can't be interacted like plugins
   * The goal of script is to just load it, and let the script itself to handle everything it needs- currently no use.
   * 
   * @async
   * @static
   * 
   * @param {array} scripts List of scripts to load.
   */  
  static async loadScripts(scripts){
    if (!scripts.length) return [];

    const head = document.head;
  
    return Promise.all(scripts.map(script => {
      return new Promise((resolve, reject) => {
        const scriptElem = document.createElement("script");
        scriptElem.src = script.src;
        scriptElem.onload = () => resolve(script);
        scriptElem.onerror = () => reject(script);
        head.appendChild(scriptElem);
      });
    }));
  }

  /**
   * Initialize this moudle, load all scripts and plugins;
   * 
   * @param {object} settings Data return from channel setting json
   * @param {array} settings.plugins List of plugins to load
   * @param {array} settings.additionalScripts List of scripts to load
   */  
  async init(settings) {
    if (!settings) return;

    const loaderHandler = await Promise.all([ChannelSettings.loadPlugins(settings.plugins), ChannelSettings.loadScripts(settings.additionalScripts)])

    this.plugins = loaderHandler[0];
    this.scripts = loaderHandler[1];

    Object.assign(this.generals, settings.generals);

    if (process.env.NODE_ENV !== "production") {
      console.log("plugins", this.plugins);
    }
  }

  /**
   * Get plugin
   * 
   * @param {string} pluginName The plugin name we want to retrieve.
   * @returns Plugin instance.
   */
  getPlugins(pluginName) {
    return this.plugins[pluginName] || [];
  }

  /**
   * Get setting
   * 
   * @param {string} field Setting name to retrieve.
   * @returns Setting value.
   */
  getSettings(field) {
    return this.generals[field];
  }
}

export default new ChannelSettings();