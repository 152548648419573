// LOGIC
import ChannelSettings from "utils/channelSettings/ChannelSettings";
import { v7Init } from "utils/jsonConverter/v7";
import langData from "data/lang.json";
import DeviceCenter from "Services/platforms/deviceCenter";
import { GET } from "Services/requests/get";
import Beacons from "Services/analytics/beacons";
import Parser from "utils/parser";
import errorReport from "Services/analytics/errorReport";
import Analytics from "Services/analytics/globalAnaltyics";

/**
* Get logic url from element or from query param.
*
* @returns Returns the logic url, error if found no hash.
*/
const getLogicUrl = () => {
  const elem = document.getElementById("jsonUrl"); // tv devices
  if (elem) {
    const _jsonURL = elem.innerText.trim();
    if (!_jsonURL)
      throw new Error("No json url found");

    return _jsonURL;
  }

  const hash = new URLSearchParams(window.location.search).get("hash");
  if (!hash)
    throw new Error("No hash specified");

  window.settings.appSettings.hash = hash;

  return `https://logic.castify.ai/srv/logic/?hash=1rk064&cad[channel_id]=${hash}&rd=1&cad[client_type]=3&cad[channel_output]=_manifest`;
}

/**
* Get logic, and reorginze the logic data.
*
* @returns Foramtted logic JSON.
*/
const getLogic = async () => {
  const { finalClickUrl, requestData = {} } = await GET(Parser.parse(getLogicUrl()));
  const { deviceData = {}, geoData = {} } = requestData;
  const userData = {
    ip: deviceData.ip,
    default_IFA: deviceData.deviceIfa,
    geoData,
    ua: {
      osName: deviceData.uaData.osName,
      osFamily: deviceData.uaData.osFamily,
      uaFamily: deviceData.uaData.uaFamily,
      deviceType: deviceData.uaData.deviceType
    }
  }

  return {
    url: finalClickUrl,
    userData
  };
}

/**
* Get the manifest.
*
* @returns Returns The parts we need from manifest.
*/
const getManifest = async (url, iso, displaySplashScreen) => {
  const manifest = await GET(url);
  const channelStatus = manifest.channel_status;

  displaySplashScreen(manifest.splash_screen);

  if (channelStatus) {
    if (channelStatus !== "Live")
      throw new Error(`App status: ${channelStatus}`, { cause: channelStatus })
  }

  // we don't return the whole manifest object since it may contiain a lot of inforamtion we don't need
  return {
    translation_url: manifest.translation_url,
    splash_screen: manifest.splash_screen,
    feed: manifest.multi_geo_content[iso] || manifest.multi_geo_content.all,
    beacons: manifest.beacons,
    channel_settings_url: manifest.channel_settings_url
  }
}

/**
* Get the translate json.
*
* @returns Returns the selected json for language or english as default.
*/
const getTranslation = async url => {
  if (!url) return langData;

  try {
    return await GET(url);
  }
  catch (error) {
    console.warn("Using default lang", error);
    return langData;
  }
}

/**
* Get channel settings json - plugins or override settings.
*
* @returns Returns the channel settings class.
*/
const getSettings = async (channelSettingsHash) => {
  if (!channelSettingsHash)
    return ChannelSettings.init();

  try {
    const settings = await GET(channelSettingsHash);
    return ChannelSettings.init(settings);
  } catch (err) {
    console.warn(`Downloading channel settings failed`, err);
    return ChannelSettings.init();
  }
}

/**
* Get main json, we get as text so we can change some text.
*
* @returns Returns the main json.
*/
const getAppJson = async (apiUrl, dataToReplace) => {
  const _jsonTXT = await GET(apiUrl, { type: "text" });

  return JSON.parse(_jsonTXT.replace(/\$\{USER_COUNTRY\}/g, dataToReplace));
};

/**
* Take any supported json vesion and change it to a structure we can work with.
*
* @returns Returns the json in a strcture the app knows.
*/
const translateJson = (json, vast) => {
  switch (json.Info.class) {
    case "7": return v7Init(json, vast);
    default: throw new Error(`Unsupported json version: ${json.Info.class}`)
  }
}

/**
* Build object under the window object.
*
* @see [docs]{@link https://docs.google.com/document/d/18JgbqCKrMQ_pdEB6pwestgALYbA6-lIskwPPgsQTt70/edit#bookmark=id.37zw6lckovky}
*/
const setWindowData = () => {
  window.settings = {
    platform: "",
    platformSettings: {},
    appSettings: {},
    deepLinkData: {}
  }
}

export default async (displaySplashScreen) => {
  try {
    setWindowData();
    // get logic
    const logic = await getLogic();
    // save the iso country code for later use
    const ISO = logic.userData.geoData.cityGeoData.countryIsoCode;
    // get the manifest
    const manifest = await getManifest(logic.url, ISO, displaySplashScreen);

    // init device, get app json, get language and channel settings.
    const data = await Promise.all([
      DeviceCenter.init(logic.userData, ISO),
      getAppJson(manifest.feed.content_feed_url, ISO),
      getTranslation(manifest.translation_url),
      getSettings(manifest.channel_settings_url)
    ]);

    // init parser module, change all constant macros 
    const [beaconUrl, beaconSession, beaconVideo, vastURL] = Parser.init({
      data: logic.userData,
      urls: [manifest.beacons.url, manifest.beacons.url_session, manifest.beacons.url_video, data[1].Ads.vastURL]
    });

    Beacons.init({ beaconUrl, beaconSession, beaconVideo });

    const videoDeepLink = window.settings.deepLinkData.video;

    Analytics.sendEvent("openApp", {
      is_deeplink: !!videoDeepLink,
      content_id: videoDeepLink,
      source: window.settings.deepLinkData.source
    });

    // return all the data the app needs
    return {
      json: translateJson(data[1], vastURL),
      translation: data[2]
    }
  }
  catch (error) {
    errorReport.trackError({
      message: error.message,
      stack: error.stack
    });

    throw error;
  }
}