import React from 'react';
import { useSelector, shallowEqual } from "react-redux";
import DefaultLoader from './DefaultLoader';

export default function Loader() {
	const { loaderImage } = useSelector(({app}) => ({
		loaderImage: app.graphic.loader_image
	}), shallowEqual);

	return loaderImage ? <img src={loaderImage} alt="loader" /> : <DefaultLoader />
}