import ReplayIcon from '@material-ui/icons/Replay';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ClosedCaptionIcon from '@material-ui/icons/ClosedCaptionOutlined';
import CommentIcon from '@material-ui/icons/Comment';

/*
action: call a function by the action type
type: how to handle click
  btn - when clicked, just activate it
  toggle - same as btn but will change it's active value
  options - when clicked will open a list of options
label: text shown
labelActive?: text shown in player when active
IconActive: icon itself
IconActive?: icon to display when toggle is on (type/toogle)
startPos?: what pos to start in the options list (type/options)
startValue: initial value (type/toggle)
display: should diplay this option
*/


/**
 * Build player options like subtitles, replay, list.
 * 
 * @param {object} video Video object 
 * @param {object} translation translation object
 * 
 * @returns player options list or null if there are no options
 */
export const buildPlayerOptions = (video, translation) => {
  const optionsList = [];

  // replay btn
  if (!video.is_live_streaming) {
    optionsList.push(createPlayerOption("btn", {
      action: "replay",
      label: translation("replay"),
      icon: ReplayIcon,
    }))
  }

  // my list
  if (window.clientPlaylists.myList) {
    optionsList.push(createPlayerOption("toggle", {
      action: "addToList",
      label: translation("addToList"),
      labelActive: translation("removeFromList"),
      icon: AddIcon,
      IconActive: RemoveIcon,
    }))
  }

  // captions
  if (video.captions && window.settings.platformSettings.subtitles) {
    optionsList.push(buildDynamicOptions("subtitles", video.captions, { off: translation("off"), subtitles: translation("subtitles") }))
  }

  // article
  if (video.action_url_settings) {
    optionsList.push(createPlayerOption("toggle", {
      action: "article",
      label: translation("fullArticle"),
      icon: CommentIcon,
    }))
  }

  return optionsList.length ? optionsList : null
}

const buildSubtitles = (captions, translations) => {
  const subtitles = captions.map(item => ({ label: item.label, value: item.srclang }))

  return createPlayerOption("list", {
    action: "subtitles",
    label: translations.subtitles,
    icon: ClosedCaptionIcon,
    startPos: 1,
    options: [{ label: translations.off, value: "off" }, ...subtitles],
  })
}

/**
 * Build Options that can be removed from app settings like: captions
 * 
 * @param {string} type 
 * @param {object} optionData 
 * @returns player option object
 */
export const buildDynamicOptions = (type, ...data) => {
  switch (type) {
    case "subtitles": return buildSubtitles(...data)
    default: throw new Error(`Unknown dynamic option: ${type}`);
  }
}

/**
 * Build player option
 * 
 * @param {string} type 
 * @param {object} optionData 
 * @returns player option object
 */
export const createPlayerOption = (type, optionData) => {
  switch (type) {
    case "btn": return new BtnOption(optionData);
    case "toggle": return new ToggleOption(optionData);
    case "list": return new ListOption(optionData);
    default: throw new Error(`Unknown player option type: ${type}`);
  }
}

class PlayerOption {
  constructor(optionData) {
    this.Icon = optionData.icon;
    this.label = optionData.label
    this.action = optionData.action;
  }
}

class BtnOption extends PlayerOption {
  constructor(optionData) {
    super(optionData);
    this.type = "btn";
  }
}

class ToggleOption extends PlayerOption {
  constructor(optionData) {
    super(optionData)
    this.type = "toggle";
    this.startValue = optionData.startValue;
    this.labelActive = optionData.labelActive;
    this.iconActive = optionData.iconActive;
  }
}

class ListOption extends PlayerOption {
  constructor(optionData) {
    super(optionData);
    this.type = "list";
    this.startPos = optionData.startPos;
    this.options = optionData.options
  }
}