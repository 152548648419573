import { useCallback } from 'react'

export default function useFunction(func, dependency = []) {

  const filteredFunction = useCallback(event => {

    if (window.settings.platformSettings.cursor)
      func(event)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [func, ...dependency]);


  return filteredFunction;
}
