import React, { useRef, useEffect, useMemo } from 'react'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import WithPage from 'HOC/withPage';
import useKeyHandler from 'hooks/useKeyHandler';
import { setKeyHandler } from "Services/redux/app/actions";
import BlueBox from '../../components/pagesWidgets/BlueBox';
import SocialItem from '../../components/pagesWidgets/SocialItem';
import { KeyHandlerCode } from 'data/constants';

import './basicStyle.css';
import WithKeyHandler from 'HOC/WithKeyHandler';
import PageDescription from '../../components/pagesWidgets/PageDescription';

function BasicPage(props) {

	const dispatch = useDispatch();
	const { outline } = useSelector(({ app }) => ({
		outline: app.graphic.outline,
	}), shallowEqual);

	const items = useRef(props.page.items ? props.page.items : [])
	const pageDescriptionRef = useRef();

	useEffect(() => {
		if (props.isActive)
			pageDescriptionRef.current && pageDescriptionRef.current.focus();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.type, props.isActive])

	const goToMenu = () => {
		dispatch(setKeyHandler(KeyHandlerCode.MENU));
	}

	useKeyHandler({
		keys: { left: goToMenu, back: goToMenu },
		isActive: props.isActive,
	});

	const setListClass = () => {
		const { page } = props;
		const itemsLength = items.current.length;
		if (!page.page_descrtipion && !page.page_footer_text && itemsLength <= 4)
			return "no_content";
		if (itemsLength <= 4)
			return "justify_center";
		else
			return "large"; //if we have more than 4 
	}

	const renderItems = () => {
		if (!props.page.items) return

		return props.page.items.map(socialItem => (
			<div className="page_item" key={socialItem.item_sub_type_id}>
				<SocialItem socialItem={socialItem} />
				<div className="social-border" />
			</div>
		))
	}

	const memoItems = useMemo(renderItems, [props.page.items]);

	return (
		<>
			<PageDescription text={props.page.page_descrtipion} id="basic-description" outline={outline} ref={pageDescriptionRef} />

			{props.page.items !== undefined && (
				<div className={`page_items ${setListClass()}`}>
					{memoItems}
				</div>
			)}

			{(props.page.page_footer_text && props.page.items.length <= 4) && <BlueBox text={props.page.page_footer_text} hasNoItems={!items.current.length && !props.page.page_descrtipion} color={props.page.graphic.text_color} />}
		</>
	)
}

export default React.memo(
	WithPage(
		WithKeyHandler(BasicPage, KeyHandlerCode.BASIC),
		KeyHandlerCode.BASIC
	)
)