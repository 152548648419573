import React from 'react';

function Watermark() {
	return (
		<div className="waterMark">
			<img src="https://cdn.castify.ai/files/app/castify_watermark.png" width="100px" style={{ opacity: .8 }} alt="castify" />
		</div>
	)
}

export default React.memo(Watermark);
