import React from 'react';

 function PageDescription({ text, id, outline }, ref) {

	const createMarkup = () => {
		return {__html: text};
	}
	return (
		<p className={"page-description" + (outline ? " outline" : "")} id={id} dangerouslySetInnerHTML={createMarkup()} tabIndex={-1} ref={ref} />
	)
}

export default React.memo(React.forwardRef(PageDescription))